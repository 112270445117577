import { useToast } from '@/manager-toast';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@/shared-ui';
import { ApolloError } from '@apollo/client';
import { faPlus, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  AddApikeyFormValues,
  addApikeyFormSchema,
} from './manager-api-access.helpers';

interface NewApiKeyDialogProps {
  onCopy: (apiKey: string) => void;
  onAdd: (data: AddApikeyFormValues) => Promise<string>;
  onClose?: () => void;
}

export const NewApiKeyDialog = ({
  onCopy,
  onAdd,
  onClose,
}: NewApiKeyDialogProps) => {
  const { addToast } = useToast();
  const [generatedToken, setGeneratedToken] = useState<string>();
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm<AddApikeyFormValues>({
    resolver: zodResolver(addApikeyFormSchema),
  });
  const hasTokenGenerated = !!generatedToken?.trim()?.length;

  useEffect(() => {
    setFocus('name');
  }, [setFocus]);

  const handleSubmitAndToast = async (data: AddApikeyFormValues) => {
    try {
      const newToken = await onAdd(data);
      addToast({ title: 'API Key generated successfully!', type: 'success' });
      setGeneratedToken(() => newToken);
    } catch (error) {
      if (error instanceof ApolloError) {
        addToast({ title: error.message, type: 'error' });
      } else {
        addToast({
          title: 'There was an error generating API key. Please try again.',
          type: 'error',
        });
        reset();
      }
    }
  };

  return (
    <Dialog
      onOpenChange={(open) => {
        if (!open) {
          reset();
          setGeneratedToken(() => '');
          onClose?.();
        }
      }}
    >
      <DialogTrigger asChild disabled={isSubmitting}>
        <Button color="primary">
          <FontAwesomeIcon icon={faPlus} />
          <span className="ml-2">Generate API Key</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle className="font-display px-8 py-2 pt-8 text-2xl font-semibold leading-tight">
          Generate API Key
        </DialogTitle>
        <DialogDescription className="px-8 py-2 text-sm text-gray-400">
          Generate your unique API key to access our services securely.
        </DialogDescription>

        <div className="px-8 py-4 text-sm text-gray-400">
          <form onSubmit={handleSubmit(handleSubmitAndToast)}>
            <label
              htmlFor="name"
              className="block text-sm font-semibold leading-tight tracking-tight text-zinc-900"
            >
              {hasTokenGenerated ? 'Token Value' : 'Name'}
            </label>
            {hasTokenGenerated ? (
              <span className="text-sm font-normal leading-tight text-zinc-400">
                {generatedToken}
              </span>
            ) : (
              <input
                placeholder="e.g, My API"
                className="mt-2 w-full rounded border border-zinc-200 px-3 py-2 text-sm font-normal leading-tight text-zinc-800 focus:shadow-sm focus:outline-none"
                readOnly={isSubmitting}
                {...register('name')}
              />
            )}
            {errors.name && (
              <span className="text-sm text-red-500">
                {errors.name.message}
              </span>
            )}
          </form>
        </div>

        <div className="flex items-center justify-end border-t px-8 py-4">
          <div className="flex items-center gap-2">
            {!hasTokenGenerated && (
              <DialogClose asChild>
                <Button color="secondary" disabled={isSubmitting}>
                  Cancel
                </Button>
              </DialogClose>
            )}
            {hasTokenGenerated && (
              <DialogClose asChild>
                <Button color="primary" onClick={() => onCopy(generatedToken)}>
                  Copy
                </Button>
              </DialogClose>
            )}
            {!hasTokenGenerated && (
              <Button
                onClick={handleSubmit(handleSubmitAndToast)}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  'Generate'
                )}
              </Button>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
