import { render } from '@react-email/components';

import { Rating } from '@/manager-graphql';

export const getHTMLEmbedCode = async (
  surveyId: string,
  primaryQuestion: string,
  ratings: Rating[]
) => {
  const { default: HTMLEmbedCode } = await import(
    './questions-html-embed-code'
  );
  return render(
    <HTMLEmbedCode
      surveyUrl={getSurveyUrl(surveyId)}
      primaryQuestion={primaryQuestion}
      ratings={ratings}
    />
  ).replace(/<!DOCTYPE(.*?)>/g, '');
};

export const getHTMLiFrameCode = (surveyId: string, isNPS?: boolean) => `<iframe
  src="${getIframeUrl(surveyId)}"
  title="Survey"
  frameborder="0"
  scrolling="no"
  style="overflow:hidden;overflow-x:hidden;overflow-y:hidden;height:${
    isNPS ? 400 : 320
  }px;width:500px;position:absolute;right:0;bottom:0">
    <p>Your browser does not support iframes.</p>
</iframe>`;

export const getSurveyUrl = (
  surveyId: string,
  baseUrl = window.location.origin
) => `${baseUrl}/survey/${surveyId}`;

export const getIframeUrl = (
  surveyId: string,
  baseUrl = window.location.origin
) => getSurveyUrl(surveyId, baseUrl).replace('/survey/', '/survey/embed/');
