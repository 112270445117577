import { type IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface StateMessageProps {
  body?: string;
  heading: string;
  icon: IconDefinition;
  iconClassName?: string;
}

export const StateMessage = ({
  body,
  heading,
  icon,
  iconClassName,
}: StateMessageProps) => {
  return (
    <div className="mx-auto flex max-w-96 flex-col items-center">
      <span className="flex aspect-square items-center rounded-full border-8 border-zinc-200 bg-zinc-300 p-2 text-zinc-500">
        <FontAwesomeIcon icon={icon} className={iconClassName} />
      </span>

      <h3 className="mt-3 font-semibold">{heading}</h3>

      {body ? (
        <p className="mt-1 text-center text-sm text-zinc-500">{body}</p>
      ) : null}
    </div>
  );
};

export default StateMessage;
