import { useQuery } from '@apollo/client';

import {
  GET_QUESTIONS_WITH_STATS,
  OrderDirection,
  QuestionOrderField,
} from '@/manager-graphql';

export const useGetQuestionsWithStats = () =>
  useQuery(GET_QUESTIONS_WITH_STATS, {
    variables: {
      orderBy: {
        direction: OrderDirection.Desc,
        field: QuestionOrderField.Messages,
      },
    },
  });
