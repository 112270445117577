export const NotFound = () => {
  return (
    <div className="fixed left-0 top-0 h-full w-full bg-white opacity-75">
      <div className="mt-[50vh] flex items-center justify-center">
        <span className="font-body font-medium text-zinc-500">Not found</span>
      </div>
    </div>
  );
};

export default NotFound;
