import { gql } from '../__generated__/gql';

export const EXPORT_INSIGHTS = gql(/* GraphQL */ `
  mutation ExportInsights {
    exportInsights {
      id
      createdAt
      expiresAt
      status
      size
      url
    }
  }
`);
