import { useMutation, useQuery, type QueryHookOptions } from '@apollo/client';

import {
  DELETE_INSIGHT,
  GET_INSIGHTS,
  GET_MESSAGES,
  UPDATE_INSIGHTS,
  type GetInsightsQuery,
  type GetInsightsQueryVariables,
  type GetMessagesQuery,
  type GetMessagesQueryVariables,
} from '@/manager-graphql';

export { useGetQuestionsWithStats } from './insight-list.hooks';

export const useGetInsightsQuery = (
  options: QueryHookOptions<GetInsightsQuery, GetInsightsQueryVariables> = {}
) => useQuery(GET_INSIGHTS, options);

export const useGetMessagesQuery = (
  options: QueryHookOptions<GetMessagesQuery, GetMessagesQueryVariables> = {}
) => useQuery(GET_MESSAGES, options);

export const useUpdateInsights = () => useMutation(UPDATE_INSIGHTS);

export const useDeleteInsight = () => useMutation(DELETE_INSIGHT);
