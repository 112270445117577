import Layout from '@/manager-sidebar';
import {
  Button,
  PageHeader,
  PageHeaderNav,
  StateMessageError,
  StateMessageLoading,
} from '@/shared-ui';
import { faCopy, faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { useGetApiKeys } from './manager-api-access-keys.hooks';
import { NewApiKeyDialog } from './manager-api-access-new-api-key-dialog';
import { DeleteApiKeyDialog } from './manager-api-access-delete-api-key-dialog';

export const ApiAccessKeys = () => {
  const {
    apiKeys,
    error,
    loading,
    handleAddApikey,
    handleDeleteApikey,
    handleCopyApikey,
    refetchKeys,
  } = useGetApiKeys();
  return (
    <Layout>
      <div className="w-full bg-zinc-50">
        <PageHeader title="API Access">
          <PageHeaderNav
            items={[
              { name: 'API Keys', to: '/api-access/keys' },
              { name: 'API Documentation', to: '/api-access/docs' },
            ]}
          />
        </PageHeader>

        <main className="space-y-6 px-10 py-6">
          {loading ? (
            <StateMessageLoading />
          ) : error ? (
            <StateMessageError message={error} />
          ) : (
            <>
              <div className="flex justify-end">
                <NewApiKeyDialog
                  onCopy={handleCopyApikey}
                  onAdd={handleAddApikey}
                  onClose={refetchKeys}
                />
              </div>

              <div className="rounded-md border">
                <table className="w-full overflow-hidden rounded-md bg-white">
                  <thead className="bg-zinc-50">
                    <tr className="">
                      <th className="p-4 text-left text-sm font-medium leading-tight text-zinc-900">
                        Name
                      </th>
                      <th className="p-4 text-left text-sm font-medium leading-tight text-zinc-900">
                        Created on
                      </th>
                      <th className="p-4 text-center text-sm font-medium leading-tight text-zinc-900"></th>
                    </tr>
                  </thead>

                  <tbody>
                    {apiKeys.map((apiKey) => (
                      <tr
                        className="w-full border-t hover:bg-slate-50"
                        key={apiKey.id}
                      >
                        <td className="p-4 text-sm font-medium leading-tight text-zinc-500">
                          {apiKey.name}
                        </td>
                        <td className="px-4 py-2 text-sm font-medium leading-tight text-zinc-500">
                          {format(apiKey.createdAt, 'MM-dd-yyyy HH:mm:ss')}
                        </td>
                        <td className="text-center">
                          <div className="flex justify-end">
                            <Button
                              color="text"
                              onClick={() => handleCopyApikey(apiKey.id)}
                            >
                              <FontAwesomeIcon icon={faCopy} />
                            </Button>
                            <DeleteApiKeyDialog
                              onDelete={() => handleDeleteApikey(apiKey.id)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </main>
      </div>
    </Layout>
  );
};
