const TESTIMONIALS = [
  {
    name: 'Matt',
    title: 'Head of Product Growth at 18Birdies',
    text: "Theysaid's AI Survey is revolutionizing how we gather insights, making surveys smarter and more engaging.",
  },
  {
    name: 'Joan',
    title: 'Head of VoC at PandaDoc',
    text: "I was so impressed by EVO I was sure they must have trained it with our company's playbook for the demo. I had to test it on another brand just to be sure. Say goodbye to generic survey question banks. EVO is next level!",
  },
  {
    name: 'Jackson',
    title: 'Product Manager at 3Advance',
    text: "TheySaid's AI survey tool is one of the most exciting practical applications of a language model yet.",
  },
];

export default function LandingTestimonials() {
  return (
    <section className="relative">
      <div className="absolute -z-10 h-full w-full bg-zinc-50">
        <div className="absolute left-0 top-0 h-56 w-56 bg-[radial-gradient(circle_at_0%_3%,_#ffeb99,_rgba(255,_235,_153,_0)_70%)] opacity-70" />
        <div className="absolute bottom-0 right-0 h-56 w-56 bg-[radial-gradient(circle_at_101%_111%,_#f47786,_rgba(244,_119,_134,_0)_70%)] opacity-40" />
      </div>

      <div className="container mx-auto">
        <div className="grid grid-cols-1 gap-8 px-6 py-8 sm:px-0 md:grid-cols-2 md:py-12 lg:grid-cols-3">
          {TESTIMONIALS.map(({ name, title, text }) => (
            <article key={title} className="flex flex-col justify-between">
              <p className="text-lg leading-normal text-zinc-700">{text}</p>

              <div className="mt-4">
                <span className="font-display block text-xl font-bold leading-tight text-zinc-950">
                  {name}
                </span>
                <span className="font-display block leading-tight text-zinc-600">
                  {title}
                </span>
              </div>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
}
