import { gql } from '../__generated__/gql';

export const GET_SIDEBAR_DATA = gql(/* GraphQL */ `
  query GetSidebarData {
    companyInfo {
      id
      website
    }
    listQuestions {
      items {
        id
        totalUnreadInsights
      }
    }
  }
`);
