import { z } from 'zod';

export const addApikeyFormSchema = z.object({
  name: z
    .string()
    .regex(
      /^[a-zA-Z0-9\s]+$/,
      'Name must be alphanumeric and can contain spaces'
    )
    .min(1, 'Name is required'),
});

export type AddApikeyFormValues = z.infer<typeof addApikeyFormSchema>;
