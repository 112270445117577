import classNames from 'classnames';
import { forwardRef } from 'react';
import { tv, VariantProps } from 'tailwind-variants';

const buttonVariants = tv({
  base: 'text-nowrap rounded text-sm font-medium leading-normal focus:outline-zinc-100 text transition-colors disabled:opacity-30 disabled:cursor-not-allowed',

  variants: {
    color: {
      primary: 'bg-red-500 text-white enabled:hover:bg-red-600',
      secondary: 'bg-zinc-200 text-zinc-800 enabled:hover:bg-zinc-300',
      text: 'text-zinc-500 enabled:hover:bg-zinc-100',
    },

    size: {
      md: 'px-4 py-2',
      lg: 'px-6 py-4',
    },
  },

  defaultVariants: {
    color: 'primary',
    size: 'md',
  },
});

interface ButtonProps
  extends Omit<React.ComponentProps<'button'>, 'color'>,
    VariantProps<typeof buttonVariants> {
  children: React.ReactNode;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, color, size, className, ...rest }, forwardedRef) => {
    return (
      <button
        {...rest}
        className={classNames(buttonVariants({ color, size }), className)}
        ref={forwardedRef}
      >
        {children}
      </button>
    );
  }
);

export default Button;
