import { gql } from '../__generated__/gql';

export const ADD_CUSTOM_QUESTION = gql(/* GraphQL */ `
  mutation AddCustomQuestion($question: String!) {
    addCustomQuestion(question: $question) {
      id
      primaryQuestion
      ratings {
        id
        label
        value
      }
      tags {
        id
        name
      }
    }
  }
`);
