import { gql } from '../__generated__/gql';

export const ADD_SYSTEM_QUESTIONS = gql(/* GraphQL */ `
  mutation AddSystemQuestions {
    addSystemQuestions {
      id
      primaryQuestion
      ratings {
        id
        label
        value
      }
      tags {
        id
        name
      }
    }
  }
`);
