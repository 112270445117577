import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  ADD_COMPANY,
  COMPANY_ADDED,
  GET_COMPANY_INFO,
  type AddCompanyMutationVariables,
} from '@/manager-graphql';

export const useHome = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { data, loading } = useQuery(GET_COMPANY_INFO, {
    fetchPolicy: 'cache-first',
  });
  useSubscription(COMPANY_ADDED, {
    onData: () => {
      navigate('company-summary');
    },
    shouldResubscribe: true,
    skip: !data,
  });
  const [addCompany, { error }] = useMutation(ADD_COMPANY);

  const setSubmitting = useCallback(
    (submitting: boolean) => {
      setSearchParams((prev) => {
        const params = new URLSearchParams(prev);
        if (submitting) {
          params.set('state', 'submitting');
        } else {
          params.delete('state');
        }
        return params;
      });
    },
    [setSearchParams]
  );

  const handleSubmit = useCallback(
    async (input: AddCompanyMutationVariables['input']) => {
      setSubmitting(true);
      await addCompany({
        onCompleted: () => {
          setSubmitting(false);
          navigate('company-summary');
        },
        onError: (error) => {
          setSubmitting(false);
          throw error;
        },
        update: (cache, { data }) => {
          if (data?.addCompany) {
            cache.modify({
              fields: {
                companyInfo() {
                  return cache.writeQuery({
                    data: { companyInfo: data.addCompany },
                    query: GET_COMPANY_INFO,
                  });
                },
              },
            });
          }
        },
        variables: { input },
      });
    },
    [addCompany, navigate, setSubmitting]
  );

  return {
    error: error?.message !== 'Invalid website' ? error?.message : undefined,
    companyInfo: data?.companyInfo,
    isInvalidWebsiteError: error?.message === 'Invalid website',
    isLoading: loading,
    isSubmitting: searchParams.get('state') === 'submitting',
    onSubmit: handleSubmit,
  };
};
