import { gql } from '../__generated__/gql';

export const UPDATE_COMPANY_INFO = gql(/* GraphQL */ `
  mutation UpdateCompanyInfo($input: OrganizationUpdateInput!) {
    updateCompanyInfo(input: $input) {
      id
      category
      description
      name
      productServices {
        benefits
        features
        id
        name
        uniqueSellingPoints
      }
      website
    }
  }
`);
