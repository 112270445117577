import {
  faArrowLeftToLine,
  faArrowRightToLine,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import { UpgradeSidebar, useUpgrade } from '@/manager-upgrade';
import SidebarFooterMenu from './manager-sidebar.footer-menu';
import { useSidebar } from './manager-sidebar.hooks';

interface SidebarProps {
  children: React.ReactNode;
}

export default function Sidebar({ children }: SidebarProps) {
  const { isExpanded, toggleExpanded } = useSidebar();
  const [isHovering, setHovering] = useState(false);
  const { hasReachedLimit, loading } = useUpgrade();

  return (
    <aside className="sticky top-0 h-screen">
      <nav
        data-expanded={isExpanded}
        className="flex h-full w-[4.75rem] flex-col border-r bg-zinc-900 shadow-sm data-[expanded=true]:w-64"
      >
        <div className="flex justify-between px-4 py-6">
          {isExpanded ? (
            <img
              src="/img/theysaid-logo-white-horizontal.svg"
              className="h-8 transition-all"
              alt="TheySaid Logo"
            />
          ) : isHovering ? null : (
            <img
              onMouseEnter={() => setHovering(true)}
              src="/img/theysaid-logo-white.svg"
              className="h-10 transition-all"
              alt="TheySaid Logo"
            />
          )}

          {isExpanded || isHovering ? (
            <button
              onClick={toggleExpanded}
              onMouseLeave={() => setHovering(false)}
              className="h-10 w-10 rounded-lg bg-zinc-700 p-1.5 text-white transition-colors hover:bg-zinc-100 hover:text-zinc-500"
            >
              {isExpanded ? (
                <FontAwesomeIcon icon={faArrowLeftToLine} size="lg" />
              ) : (
                <FontAwesomeIcon icon={faArrowRightToLine} size="lg" />
              )}
            </button>
          ) : null}
        </div>

        <ul className="flex-1 space-y-4 px-4">{children}</ul>

        {!loading && hasReachedLimit && (
          <UpgradeSidebar isExpanded={isExpanded} />
        )}
        <SidebarFooterMenu />
      </nav>
    </aside>
  );
}
