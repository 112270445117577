interface ChipProps {
  content: number;
}

export const Chip = ({ content }: ChipProps) => {
  return (
    <div className="flex h-3.5 min-w-3.5 items-center justify-center rounded-full bg-red-700 p-1">
      <span className="text-[0.5rem] font-medium text-white">{content}</span>
    </div>
  );
};

export default Chip;
