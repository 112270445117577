import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

import { Chip } from '@/shared-ui';

import { useSidebar } from './manager-sidebar.hooks';

interface SidebarItemProps {
  icon: IconDefinition;
  text: React.ReactNode;
  to: React.ComponentProps<typeof NavLink>['to'];
  alert?: number | null;
}

export default function SidebarItem({
  icon,
  text,
  to,
  alert,
}: SidebarItemProps) {
  const { isExpanded } = useSidebar();

  return (
    <li>
      <NavLink
        to={to}
        className={({ isActive }) =>
          `group relative flex h-10 cursor-pointer items-center justify-center rounded-md font-medium transition-colors
          ${
            isActive
              ? 'bg-zinc-700 text-white'
              : 'text-zinc-400 hover:bg-zinc-50'
          }
      `
        }
      >
        <span
          data-expanded={isExpanded}
          className="w-10 text-center data-[expanded=true]:w-14"
        >
          <FontAwesomeIcon icon={icon} size="lg" />
        </span>

        <span
          data-expanded={isExpanded}
          className="w-0 overflow-hidden tracking-wide data-[expanded=true]:w-40"
        >
          {text}
        </span>

        {typeof alert === 'number' ? (
          <div
            data-expanded={isExpanded}
            className="absolute right-1 top-1 data-[expanded=true]:right-4 data-[expanded=true]:top-auto"
          >
            <Chip content={alert} />
          </div>
        ) : null}

        {!isExpanded && (
          <div className="invisible absolute left-full ml-6 -translate-x-3 whitespace-nowrap rounded-md bg-zinc-100 px-2 py-1 text-sm text-zinc-800 opacity-20 transition-all group-hover:visible group-hover:translate-x-0 group-hover:opacity-100">
            {text}
          </div>
        )}
      </NavLink>
    </li>
  );
}
