import { gql } from '../__generated__/gql';

export const GET_DOWNLOADS = gql(/* GraphQL */ `
  query GetDownloads {
    downloads {
      id
      createdAt
      expiresAt
      status
      size
      url
    }
  }
`);
