import { Navigate, useNavigate } from 'react-router-dom';

import CompanySummary from './company-summary';
import { useGetCompanyInfo } from './company-summary.hooks';

export const CompanySummaryContainer = () => {
  const navigate = useNavigate();
  const {
    data: companyInfoData,
    error: companyInfoError,
    loading,
  } = useGetCompanyInfo();

  const handleQuestionNavigation = () => {
    navigate('/questions');
  };

  if (companyInfoError?.message === 'Organization not found') {
    return <Navigate replace to={'..'} />;
  }

  return (
    <CompanySummary
      companyInfo={companyInfoData?.companyInfo}
      error={companyInfoError?.message}
      loading={loading}
      onCheckoutAIGeneratedQuestions={handleQuestionNavigation}
    />
  );
};

export default CompanySummaryContainer;
