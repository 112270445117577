import { gql } from '../__generated__/gql';

export const GET_COMPANY_INFO = gql(/* GraphQL */ `
  query GetCompanyInfo {
    companyInfo {
      id
      name
      category
      description
      productServices {
        id
        name
        benefits
        features
        uniqueSellingPoints
      }
      website
    }
  }
`);
