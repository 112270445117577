import {
  faBadgeDollar,
  faTriangleExclamation,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import type { GetQuestionsWithStatsQuery } from '@/manager-graphql';
import { Chip } from '@/shared-ui';
import { isNPSQuestion } from './insight-list-item.helpers';

const STANDARD_TAG_NAMES = ['ai', 'system', 'user', 'edited'];

type InsightListItemProps =
  GetQuestionsWithStatsQuery['listQuestions']['items'][number];

export const InsightListItem = (props: InsightListItemProps) => {
  return (
    <article className="flex h-full w-full flex-col justify-between divide-y rounded-xl border bg-white hover:shadow">
      <div className="flex grow flex-col justify-between gap-4 p-4">
        <div className="flex justify-between gap-1">
          <div className="text-sm font-medium leading-normal">
            {props.primaryQuestion}
          </div>

          {props.totalUnreadInsights ? (
            <Chip content={props.totalUnreadInsights} />
          ) : null}
        </div>

        <div className="grid grid-cols-2 gap-1">
          <div className="flex min-w-28 items-center justify-between gap-1 rounded bg-zinc-50 px-3 py-1">
            <span className="text-xs font-medium leading-tight text-zinc-700">
              Insights
            </span>
            <span className="text-lg font-semibold text-zinc-800">
              {props.totalInsights ?? 0}
            </span>
          </div>

          <div className="flex min-w-28 items-center justify-between gap-1 rounded bg-zinc-50 px-3 py-1">
            <span className="text-xs font-medium leading-tight text-zinc-700">
              Conv. Depth
            </span>
            <span className="text-lg font-semibold text-zinc-800">
              {props.avgMessageDepth?.toFixed(2) ?? 0}
            </span>
          </div>
        </div>
      </div>

      <div className="flex items-center justify-between gap-4 p-4">
        <div className="flex items-center gap-2">
          <span className="text-xs font-medium leading-tight text-zinc-700">
            {isNPSQuestion(props?.tags) ? 'NPS' : 'Avg'} Score:
          </span>
          <span className="rounded bg-zinc-50 px-2 text-lg font-semibold text-zinc-800">
            {props.avgScore?.toFixed(2)}
          </span>
        </div>

        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1">
            {props.tags
              ?.filter((tag) => !STANDARD_TAG_NAMES.includes(tag.name))
              .map((tag) => (
                <span
                  key={tag.id}
                  className="rounded bg-zinc-50 px-2 py-1 text-xs font-medium leading-tight text-zinc-600"
                >
                  {tag.name}
                </span>
              ))}
          </div>

          <span className="rounded bg-red-50 px-2 py-1 text-xs font-medium leading-tight">
            <FontAwesomeIcon
              className="mr-1 text-red-700"
              icon={faTriangleExclamation}
            />
            {props.totalRiskSignals ?? 0}
          </span>

          <span className="rounded bg-emerald-50 px-2 py-1 text-xs font-medium leading-tight">
            <FontAwesomeIcon
              className="mr-1 text-emerald-700"
              icon={faBadgeDollar}
            />
            {props.totalGrowthSignals ?? 0}
          </span>
        </div>
      </div>
    </article>
  );
};

export default InsightListItem;
