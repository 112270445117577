import { type ComponentProps, lazy, Suspense } from 'react';

import Layout from '@/manager-sidebar';
import { StateMessageError, StateMessageLoading } from '@/shared-ui';

import { CompanyInfoForm } from './company-info-form';
const HomeAnimation = lazy(() => import('./home-animation'));

interface HomeProps extends ComponentProps<typeof CompanyInfoForm> {
  error?: string;
  isInvalidWebsiteError: boolean;
  loading: boolean;
  submitting: boolean;
}

export default function Home({
  error,
  isInvalidWebsiteError,
  loading,
  onSubmit,
  submitting,
}: HomeProps) {
  return (
    <Layout>
      <div className="w-full bg-white">
        <main className="flex h-full items-center justify-center px-10 py-6">
          <div className="flex max-w-lg flex-col gap-11">
            {loading ? (
              <StateMessageLoading />
            ) : error ? (
              <StateMessageError message={error} />
            ) : submitting ? (
              <Suspense fallback={<StateMessageLoading />}>
                <HomeAnimation />
              </Suspense>
            ) : (
              <CompanyInfoForm
                isInvalidWebsiteError={isInvalidWebsiteError}
                onSubmit={onSubmit}
              />
            )}
          </div>
        </main>
      </div>
    </Layout>
  );
}
