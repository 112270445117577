import { gql } from '../__generated__/gql';

export const GET_QUESTIONS_WITH_STATS = gql(/* GraphQL */ `
  query GetQuestionsWithStats($orderBy: QuestionOrder) {
    listQuestions(orderBy: $orderBy) {
      items {
        id
        primaryQuestion
        avgScore
        avgMessageDepth
        totalInsights
        totalGrowthSignals
        totalRiskSignals
        totalUnreadInsights
        tags {
          id
          name
        }
      }
    }
  }
`);
