import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';

import { GET_SIDEBAR_DATA } from '@/manager-graphql';

const EXPANDED_KEY = '@TheySaid:sidebarExpanded';

const sidebarState = atom({
  key: 'sidebarState',
  default: {
    isExpanded: window.localStorage.getItem(EXPANDED_KEY) !== 'false',
  },
});

export function useSidebarData() {
  const { data } = useQuery(GET_SIDEBAR_DATA, { fetchPolicy: 'cache-first' });

  const totalUnreadInsights: number =
    data?.listQuestions.items.reduce(
      (acc, item) => acc + (item.totalUnreadInsights ?? 0),
      0
    ) ?? 0;

  return {
    hasValidOrg: !!data?.companyInfo?.website?.trim()?.length,
    totalUnreadInsights,
  };
}

export function useSidebar() {
  const [state, setState] = useRecoilState(sidebarState);

  const toggleExpanded = () => {
    setState((prevState) => ({
      ...prevState,
      isExpanded: !prevState.isExpanded,
    }));
  };

  useEffect(() => {
    window.localStorage.setItem(EXPANDED_KEY, state.isExpanded.toString());
  }, [state.isExpanded]);

  return { isExpanded: state.isExpanded, toggleExpanded };
}
