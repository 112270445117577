import { Navigate, useParams } from 'react-router-dom';

import InsightDetailContainer from './insight-detail.container';
import { parseInsightDetailPageParams } from './insight-detail.helpers';

export const InsightDetailPage = () => {
  const id = parseInsightDetailPageParams(useParams<{ id: string }>());
  return id ? <InsightDetailContainer questionId={id} /> : <Navigate to=".." />;
};

export default InsightDetailPage;
