import { useMutation, useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';

import {
  DownloadStatus,
  EXPORT_INSIGHTS,
  GET_DOWNLOADS,
} from '@/manager-graphql';

const TWENTY_SECONDS = 10_000;

export function useDownloads() {
  const [state, setState] = useState({ hasPending: false, hasNew: false });
  const { data, loading } = useQuery(GET_DOWNLOADS, {
    pollInterval: state.hasPending ? TWENTY_SECONDS : 0,
  });
  const [exportInsights] = useMutation(EXPORT_INSIGHTS, {
    refetchQueries: [GET_DOWNLOADS],
  });

  const { downloads, processing } = useMemo(() => {
    const downloads = [];
    const processing = [];
    if (data?.downloads) {
      for (const download of data.downloads) {
        if (
          download.status === DownloadStatus.Pending ||
          download.status === DownloadStatus.Processing
        ) {
          processing.push(download);
        } else {
          downloads.push(download);
        }
      }
      setState((prevState) => ({
        hasPending: processing.length > 0,
        hasNew: prevState.hasPending && processing.length === 0,
      }));
    }
    return { downloads, processing };
  }, [data?.downloads]);

  return {
    ...state,
    downloads,
    exportInsights,
    loading,
    processing,
  };
}
