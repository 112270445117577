import { useCallback } from 'react';
import { Navigate } from 'react-router-dom';

import { GetDashboardDocument } from '@/manager-graphql';
import { useToast } from '@/manager-toast';
import { useMeasurement } from '@/measurement';

import Dashboard from './dashboard';
import {
  useDashboardFilter,
  useGenerateDashboardSummary,
  useGetDashboard,
} from './dashboard.hooks';

export const DashboardContainer = () => {
  const [filter] = useDashboardFilter();
  const measurement = useMeasurement();
  const { addToast } = useToast();
  const {
    data: dashboardData,
    error: dashboardError,
    loading: loadingDashboard,
  } = useGetDashboard({ variables: { filter } });
  const [generateSummary, { error: summaryError, loading: loadingSummary }] =
    useGenerateDashboardSummary();
  const handleGenerateSummary = useCallback(() => {
    generateSummary({
      variables: { input: filter },
      update: (cache, { data }) => {
        if (dashboardData && data?.generateDashboardSummary) {
          cache.writeQuery({
            query: GetDashboardDocument,
            variables: { filter },
            data: {
              dashboard: {
                ...dashboardData.dashboard,
                summary: data.generateDashboardSummary,
              },
            },
          });
        } else if (dashboardData && data?.generateDashboardSummary === null) {
          addToast({
            title: 'No summary yet',
            type: 'info',
          });
        }
        measurement?.track('Generate Summary clicked');
      },
    });
  }, [generateSummary, filter, dashboardData, addToast, measurement]);

  if (dashboardError?.message === 'Organization not found') {
    return <Navigate replace to={'..'} />;
  }

  return (
    <Dashboard
      dashboard={dashboardData?.dashboard}
      error={dashboardError?.message || summaryError?.message}
      loadingDashboard={loadingDashboard}
      loadingSummary={loadingSummary}
      onGenerateSummary={handleGenerateSummary}
    />
  );
};

export default DashboardContainer;
