import Layout from '@/manager-sidebar';
import {
  PageHeader,
  PageHeaderNav,
  StateMessageError,
  StateMessageLoading,
} from '@/shared-ui';

import CompanySummaryForm from './company-summary-form';

interface CompanySummaryProps
  extends React.ComponentProps<typeof CompanySummaryForm> {
  error?: string;
  loading: boolean;
}

export const CompanySummaryEdit = ({
  companyInfo,
  error,
  loading,
  onSubmit,
}: CompanySummaryProps) => {
  return (
    <Layout>
      <div className="w-full bg-zinc-50">
        <PageHeader title="Evo AI Company Summary">
          <PageHeaderNav
            items={[
              { name: 'Dashboard', to: '/home/dashboard' },
              { name: 'Company Summary', to: '/home/company-summary' },
            ]}
          />
        </PageHeader>

        <main className="px-10 py-6">
          {loading ? (
            <StateMessageLoading />
          ) : error || !companyInfo ? (
            <StateMessageError
              message={error || 'Failed to load company info'}
            />
          ) : (
            <CompanySummaryForm companyInfo={companyInfo} onSubmit={onSubmit} />
          )}
        </main>
      </div>
    </Layout>
  );
};

export default CompanySummaryEdit;
