import { ApolloError } from '@apollo/client';
import { faPlus, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { DialogTrigger } from '@radix-ui/react-dialog';
import { useEffect, useState } from 'react';
import { useForm, type SubmitHandler } from 'react-hook-form';

import { useToast } from '@/manager-toast';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/shared-ui';

import {
  InviteUserFormValues,
  inviteUserFormSchema as formSchema,
} from './user-management-invite-user.helpers';

interface InviteUserProps {
  onSubmit: SubmitHandler<InviteUserFormValues>;
}

export default function InviteUser({ onSubmit }: InviteUserProps) {
  const { addToast } = useToast();
  const [isOpen, setOpen] = useState(false);
  const {
    formState: { errors, isSubmitting },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm<InviteUserFormValues>({ resolver: zodResolver(formSchema) });

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  const handleSubmitAndToast = async (data: InviteUserFormValues) => {
    try {
      await onSubmit(data);
      addToast({ title: 'User invited successfully!', type: 'success' });
    } catch (error) {
      if (error instanceof ApolloError) {
        addToast({ title: error.message, type: 'error' });
      } else {
        addToast({
          title: 'There was an error inviting the user. Please try again.',
          type: 'error',
        });
      }
    } finally {
      setOpen(false);
      reset();
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <FontAwesomeIcon icon={faPlus} className="mr-2" />
          Invite user
        </Button>
      </DialogTrigger>

      <DialogContent>
        <DialogTitle className="font-display px-8 pt-8 text-2xl font-semibold leading-loose tracking-tight text-zinc-900">
          Invite a user to TheySaid AI
        </DialogTitle>
        <DialogDescription className="font-display px-8 text-sm font-normal leading-tight text-zinc-500">
          Invite a new user to join the system. They will receive an email with
          instructions to join.
        </DialogDescription>

        <form
          onSubmit={handleSubmit(handleSubmitAndToast)}
          className="my-6 px-8"
        >
          <label
            htmlFor="email"
            className="block text-sm font-semibold leading-tight tracking-tight text-zinc-900"
          >
            Share via email
          </label>
          <input
            placeholder="user@example.com"
            className="mt-2 w-full rounded border border-zinc-200 px-3 py-2 text-sm font-normal leading-tight text-zinc-800 focus:shadow-sm focus:outline-none"
            {...register('email')}
          />
          {errors.email && (
            <span className="text-sm text-red-500">{errors.email.message}</span>
          )}
        </form>

        <div className="flex items-center justify-between border-t px-8 py-4">
          <div />

          <div className="flex items-center gap-1">
            <DialogClose asChild>
              <Button color="secondary">Cancel</Button>
            </DialogClose>

            <Button
              onClick={handleSubmit(handleSubmitAndToast)}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                'Invite'
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
