import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@/shared-ui';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface DeleteApiKeyDialogProps {
  onDelete: () => void;
}

export const DeleteApiKeyDialog = ({ onDelete }: DeleteApiKeyDialogProps) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button color="text">
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle className="font-display px-8 py-2 pt-8 text-2xl font-semibold leading-tight">
          Delete API Key
        </DialogTitle>
        <DialogDescription className="mb-5 px-8 py-2 text-sm text-gray-400">
          Are you sure you want to delete this API key?
        </DialogDescription>
        <div className="flex items-center justify-end border-t px-8 py-4">
          <div className="flex items-center gap-2">
            <DialogClose asChild>
              <Button color="primary">Cancel</Button>
            </DialogClose>
            <DialogClose asChild>
              <Button color="secondary" onClick={onDelete}>
                Delete
              </Button>
            </DialogClose>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
