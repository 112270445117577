import { useAutoAnimate } from '@formkit/auto-animate/react';
import { faFileExport, faLoader } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Content,
  Item,
  Label,
  Portal,
  Root,
  Separator,
  Trigger,
} from '@radix-ui/react-dropdown-menu';
import { useSearchParams } from 'react-router-dom';

import { BillingPlanCode } from '@/manager-graphql';
import { useUpgrade } from '@/manager-upgrade';
import { Button } from '@/shared-ui';
import InsightListDownloadsItem from './insight-list-downloads-item';
import { useDownloads } from './insight-list-downloads.hooks';

export default function InsightListDownloads() {
  const [parent] = useAutoAnimate();
  const { downloads, exportInsights, hasNew, loading, processing } =
    useDownloads();
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleToggleUpgrade, planDetails } = useUpgrade();

  const handleExportInsights = async () => {
    await exportInsights();
  };

  const handleOpenChange = (open: boolean) => {
    if (planDetails?.planCode === BillingPlanCode.Free) {
      handleToggleUpgrade();
      return;
    }

    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      if (open) {
        params.set('downloads', 'open');
      } else {
        params.delete('downloads');
      }
      return params;
    });
  };

  return (
    <Root
      open={searchParams.get('downloads') === 'open'}
      onOpenChange={handleOpenChange}
    >
      <Trigger className="flex h-9 w-9 items-center justify-center rounded bg-zinc-200 text-zinc-800 focus:outline-zinc-100 enabled:hover:bg-zinc-300">
        {hasNew ? (
          <span className="absolute mb-8 ml-8 h-2 w-2 rounded-full bg-red-700" />
        ) : null}
        <FontAwesomeIcon icon={faFileExport} />
      </Trigger>

      <Portal>
        <Content
          className="mr-10 flex w-96 flex-col rounded-lg bg-white p-6 shadow"
          sideOffset={12}
        >
          <Label className="mb-4 text-sm font-semibold leading-tight tracking-tight text-zinc-900">
            Downloads
          </Label>

          {processing.length ? (
            processing.map((download) => (
              <InsightListDownloadsItem key={download.id} download={download} />
            ))
          ) : (
            <Item asChild onSelect={(e) => e.preventDefault()}>
              <Button
                color="secondary"
                disabled={loading}
                onClick={handleExportInsights}
              >
                Export CSV
              </Button>
            </Item>
          )}

          {downloads.length || loading ? (
            <Separator className="my-4 w-full border-t" />
          ) : null}

          <div className="max-h-96 overflow-y-auto">
            <div className="flex flex-col gap-2" ref={parent}>
              {!downloads.length && loading ? (
                <FontAwesomeIcon icon={faLoader} spin />
              ) : (
                downloads.map((download) => (
                  <Item asChild key={download.id}>
                    <InsightListDownloadsItem download={download} />
                  </Item>
                ))
              )}
            </div>
          </div>
        </Content>
      </Portal>
    </Root>
  );
}
