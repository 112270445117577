import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons';

import StateMessage from './ui.state-message';

interface StateMessageErrorProps {
  title?: string;
  message: string;
}

export const StateMessageError = ({
  title = 'An error occurred',
  message,
}: StateMessageErrorProps) => {
  return (
    <div className="flex min-h-[80vh] min-w-full items-center">
      <StateMessage
        body={message}
        heading={title}
        icon={faExclamationTriangle}
      />
    </div>
  );
};

export default StateMessageError;
