import { faArrowRotateRight, faLoader } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'react-markdown';
import rehypeExtLinks from 'rehype-external-links';

import type { GetDashboardSummaryQuery } from '@/manager-graphql';

interface DashboardSummaryProps {
  loading: boolean;
  onGenerateSummary: () => void;
  summary?: GetDashboardSummaryQuery['dashboardSummary']['summary'];
}

export const DashboardSummary = ({
  loading,
  onGenerateSummary,
  summary,
}: DashboardSummaryProps) => {
  return (
    <article className="flex flex-col gap-4 rounded-lg border border-zinc-200 bg-white p-6 shadow-sm hover:shadow">
      <div className="flex justify-between">
        <div>
          <h3 className="font-semibold">Here's what They Said:</h3>
          <p className="mt-1 text-sm text-zinc-500">
            AI generated from your customers comments
          </p>
        </div>

        <div>
          {loading ? (
            <div className="space-x-2 px-4 py-2">
              <FontAwesomeIcon icon={faLoader} className="animate-spin-slow" />
              <span className="text-sm font-medium">Generating Summary</span>
            </div>
          ) : summary ? (
            <button
              className="flex h-8 w-8 items-center justify-center rounded border bg-white transition-colors hover:bg-zinc-100"
              disabled={loading}
              onClick={onGenerateSummary}
            >
              <FontAwesomeIcon icon={faArrowRotateRight} size="sm" />
            </button>
          ) : (
            <button
              className="rounded bg-red-600 px-4 py-2 text-sm font-medium leading-normal text-white transition-colors hover:bg-red-700"
              disabled={loading}
              onClick={onGenerateSummary}
            >
              Generate Summary
            </button>
          )}
        </div>
      </div>

      {summary ? (
        <div className="prose max-w-none text-sm">
          <Markdown
            rehypePlugins={[
              [
                rehypeExtLinks,
                {
                  target: '_blank',
                },
              ],
            ]}
          >
            {summary}
          </Markdown>
        </div>
      ) : null}
    </article>
  );
};

export default DashboardSummary;
