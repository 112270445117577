import { Navigate, useNavigate } from 'react-router-dom';

import { type UpdateCompanyInfoMutationVariables } from '@/manager-graphql';

import CompanySummaryEdit from './company-summary-edit';
import {
  useGetCompanyInfo,
  useUpdateCompanyInfo,
} from './company-summary-edit.hooks';

export const CompanySummaryContainer = () => {
  const { data, error, loading } = useGetCompanyInfo();
  const [updateCompanyInfo] = useUpdateCompanyInfo();
  const navigate = useNavigate();

  const handleUpdateCompanyInfo = async (
    input: UpdateCompanyInfoMutationVariables['input']
  ) => {
    Reflect.deleteProperty(input, 'name');
    Reflect.deleteProperty(input, 'website');
    const response = await updateCompanyInfo({ variables: { input } });
    navigate(-1);
    return response;
  };

  if (error?.message === 'Organization not found') {
    return <Navigate replace to={'..'} />;
  }

  return (
    <CompanySummaryEdit
      companyInfo={data?.companyInfo}
      error={error?.message}
      loading={loading}
      onSubmit={handleUpdateCompanyInfo}
    />
  );
};

export default CompanySummaryContainer;
