import { Rating } from '@/manager-graphql';
import {
  faBatteryFull,
  faSignalBars,
  faWifi,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { QuestionsSurveyRatingsPreview } from './questions-survey-ratings-preview';

interface QuestionsSurveyMobilePreviewProps {
  question: string;
  ratings: Rating[];
}

export const QuestionsSurveyMobilePreview = ({
  question,
  ratings,
}: QuestionsSurveyMobilePreviewProps) => {
  return (
    <div className="m-6 text-xs">
      <div className="shadow-base m-auto w-80 rounded-3xl border-8 border-zinc-200 bg-white pb-10">
        <div className="flex h-9 items-center justify-between pl-2">
          <span className="text-bold w-1 text-zinc-600">
            {format(new Date(), 'H:mm')}
          </span>
          <div className="flex h-3">
            <span className="-mt-3 ml-12 h-4 w-20 rounded-bl-3xl rounded-br-3xl bg-zinc-200" />
            <span className="-ml-16 -mt-1.5 h-1 w-12 bg-zinc-300" />
          </div>
          <div className="flex items-center space-x-1 pl-4 pr-4">
            <FontAwesomeIcon icon={faSignalBars} fontSize={8} />
            <span className="w-1" />
            <FontAwesomeIcon icon={faWifi} fontSize={8} />
            <span className="w-1" />
            <FontAwesomeIcon icon={faBatteryFull} fontSize={8} />
          </div>
        </div>
        <div className="mb-40 ml-5 mr-5 mt-40 flex bg-transparent">
          <QuestionsSurveyRatingsPreview
            question={question}
            ratings={ratings}
          />
        </div>
      </div>
    </div>
  );
};
