import { gql } from '../__generated__/gql';

export const AUTHENTICATE_WITH_CODE = gql(/* GraphQL */ `
  mutation AuthenticateWithCode($code: String!) {
    authenticateWithCode(code: $code) {
      accessToken
      refreshToken
      user {
        email
        firstName
        id
        lastName
        profilePictureUrl
      }
    }
  }
`);
