import { faEllipsisVertical } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Content,
  Item,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-dropdown-menu';

interface MenuListProps {
  children: React.ReactNode;
}

function MenuList({ children }: MenuListProps) {
  return (
    <Root modal={false}>
      <Trigger className="h-8 w-8 focus:outline-zinc-100">
        <FontAwesomeIcon icon={faEllipsisVertical} />
      </Trigger>

      <Portal>
        <Content
          side="bottom"
          className="inline-flex -translate-x-12 flex-col gap-1 rounded bg-white p-1 shadow"
        >
          {children}
        </Content>
      </Portal>
    </Root>
  );
}

interface MenuItemProps {
  children: React.ReactNode;
  onClick: () => void;
}

function MenuItem({ children, onClick }: MenuItemProps) {
  return (
    <Item asChild>
      <button
        className="w-full rounded px-4 py-2 text-zinc-900 transition-colors  last:text-red-700 hover:bg-zinc-100 focus:outline-none"
        onClick={onClick}
      >
        {children}
      </button>
    </Item>
  );
}

export { MenuItem, MenuList };
