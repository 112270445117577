import { Button } from '@/shared-ui';
import { faTriangleExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogTrigger } from '@radix-ui/react-dialog';
import { Content, Portal, Root, Trigger } from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';
import { UpgradeStripeDialog } from './manager-upgrade-dialog';
import { useUpgrade } from './manager-upgrade.hooks';

const UpgradeContent = () => {
  const { handleToggleUpgrade } = useUpgrade();
  return (
    <div className="m-2 flex max-h-60 max-w-60 flex-col gap-5 p-2 text-center">
      <span className="text-sm font-semibold text-white">Upgrade now</span>
      <span className="text- text-sm text-gray-400">
        You've reached your monthly response limit.
      </span>
      <span className="text-sm text-gray-400">
        Upgrade now to unlock more conversations and new features.
      </span>
      <UpgradeStripeDialog>
        <DialogTrigger asChild onClick={handleToggleUpgrade}>
          <Button color="primary">Upgrade Now</Button>
        </DialogTrigger>
      </UpgradeStripeDialog>
    </div>
  );
};

interface UpgradeSidebarProps {
  isExpanded?: boolean;
}

export const UpgradeSidebar = ({ isExpanded }: UpgradeSidebarProps) => {
  return (
    <div
      className={classNames('rounded-md border-zinc-800', {
        'bg-zinc-800': isExpanded,
        'm-5': isExpanded,
      })}
    >
      <Root>
        <Trigger
          aria-label="Upgrade"
          data-expanded={isExpanded}
          className="group relative flex w-full flex-col rounded-full pt-5 text-center last:flex focus:outline-none data-[expanded=true]:rounded-md"
        >
          {isExpanded ? (
            <div className="font-display m-auto flex h-10 w-10 items-center justify-center rounded-full bg-red-500 text-xl uppercase text-white">
              <FontAwesomeIcon icon={faTriangleExclamation} />
            </div>
          ) : (
            <div className=" m-auto overflow-hidden">
              <div className="font-display flex h-10 w-10 items-center justify-center rounded-full bg-rose-700 text-xl uppercase text-white">
                <FontAwesomeIcon icon={faTriangleExclamation} />
              </div>
              <div className="invisible absolute left-full -mt-7 ml-6 whitespace-nowrap rounded-md bg-zinc-100 px-2 py-1 text-sm text-zinc-800 opacity-20 transition-all group-hover:visible group-hover:-translate-x-3 group-hover:opacity-100">
                Upgrade
              </div>
            </div>
          )}
        </Trigger>
        {isExpanded ? (
          <UpgradeContent />
        ) : (
          <Portal>
            <Content
              side="right"
              className="data-[side=right]:animate-slide-left-and-fade inline-flex flex-col items-center justify-start gap-1 rounded-xl bg-zinc-800 p-1 shadow will-change-[opacity,transform]"
              sideOffset={10}
            >
              <UpgradeContent />
            </Content>
          </Portal>
        )}
      </Root>
    </div>
  );
};
