interface PageHeaderProps {
  children?: React.ReactNode;
  headerRight?: React.ReactNode;
  title: string;
}

export const PageHeader = ({
  children,
  headerRight,
  title,
}: PageHeaderProps) => {
  return (
    <header className="sticky top-0 shadow-sm">
      <div className="flex h-14 items-center justify-between border-b border-s-zinc-200 bg-white px-10">
        <h1 className="bg-white font-semibold text-zinc-900">{title}</h1>
        {headerRight}
      </div>
      {children}
    </header>
  );
};

export default PageHeader;
