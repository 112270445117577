import { GetQuestionsQuery } from '@/manager-graphql';
import {
  faChevronDown,
  faLaptop,
  faMobileNotch,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { QuestionsSurveyDesktopPreview } from './questions-survey-desktop-preview';
import { QuestionsSurveyMobilePreview } from './questions-survey-mobile-preview';

interface QuestionsSurveyPreviewProps {
  question: GetQuestionsQuery['listQuestions']['items'][number]['primaryQuestion'];
  ratings: GetQuestionsQuery['listQuestions']['items'][number]['ratings'];
}

export const QuestionsSurveyPreview = ({
  question,
  ratings,
}: QuestionsSurveyPreviewProps) => {
  const [viewMode, setViewMode] = useState<'mobile' | 'desktop'>('desktop');

  return (
    <div className="container flex flex-col space-y-2">
      <div className="mb-4 inline-flex">
        <button
          className="flex h-8 w-10 items-center justify-center rounded-bl rounded-tl border bg-white hover:bg-slate-100 data-[mode=mobile]:bg-red-500 data-[mode=mobile]:text-white data-[mode=mobile]:hover:bg-red-500"
          onClick={() => setViewMode('mobile')}
          data-mode={viewMode}
        >
          <FontAwesomeIcon icon={faMobileNotch} />
        </button>
        <button
          className="flex h-8 w-10 items-center justify-center rounded-br rounded-tr border bg-white hover:bg-slate-100 data-[mode=desktop]:bg-red-500 data-[mode=desktop]:text-white data-[mode=desktop]:hover:bg-red-500"
          onClick={() => setViewMode('desktop')}
          data-mode={viewMode}
        >
          <FontAwesomeIcon icon={faLaptop} />
        </button>
      </div>
      <details open={false} className="group rounded-md border bg-zinc-100">
        <summary className="flex items-center justify-between px-6 py-3 marker:content-none hover:cursor-pointer group-open:border-b ">
          <span className="text-sm font-medium leading-normal text-zinc-500">
            See pulse preview
          </span>
          <FontAwesomeIcon
            className="text-xs text-zinc-500 transition-transform group-open:-rotate-180"
            icon={faChevronDown}
          />
        </summary>

        {viewMode === 'desktop' ? (
          <QuestionsSurveyDesktopPreview
            question={question}
            ratings={ratings}
          />
        ) : (
          <QuestionsSurveyMobilePreview question={question} ratings={ratings} />
        )}
      </details>
    </div>
  );
};
