import { Navigate } from 'react-router-dom';

import Home from './home';
import { useHome } from './home.hooks';

export const HomeContainer = () => {
  const {
    companyInfo,
    error,
    isInvalidWebsiteError,
    isLoading,
    isSubmitting,
    onSubmit,
  } = useHome();

  if (!companyInfo) {
    return (
      <Home
        error={error}
        isInvalidWebsiteError={isInvalidWebsiteError}
        loading={isLoading}
        onSubmit={onSubmit}
        submitting={isSubmitting}
      />
    );
  }

  return <Navigate replace to={'dashboard'} />;
};

export default HomeContainer;
