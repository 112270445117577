import { gql } from '../__generated__/gql';

export const GET_QUESTIONS = gql(/* GraphQL */ `
  query GetQuestions(
    $after: Cursor
    $filter: QuestionListFilterInput
    $first: Int
    $orderBy: QuestionOrder
  ) {
    listQuestions(
      after: $after
      first: $first
      filter: $filter
      orderBy: $orderBy
    ) {
      items {
        id
        primaryQuestion
        tags {
          id
          name
        }
        ratings {
          id
          label
          value
        }
        totalInsights
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);
