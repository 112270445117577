import { gql } from '../__generated__/gql';

export const GET_INSIGHTS = gql(/* GraphQL */ `
  query GetInsights($after: Cursor, $filter: InsightListFilterInput!) {
    listInsights(after: $after, filter: $filter) {
      items {
        id
        contact {
          id
          firstName
          lastName
          email
          phone
        }
        createdAt
        followup
        msgRead
        primaryAnswer
        signal
        summary
        hasMessages
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);
