import { gql } from '../__generated__/gql';

export const ADD_AI_QUESTIONS = gql(/* GraphQL */ `
  mutation AddAIQuestions {
    addAIQuestions {
      id
      primaryQuestion
      tags {
        id
        name
      }
    }
  }
`);
