import { useAuth } from '@workos-inc/authkit-react';
import { useEffect } from 'react';

import Redirecting from './auth.redirecting';

export default function SignInPage() {
  const { signIn } = useAuth();

  useEffect(() => {
    signIn({ state: { returnTo: 'home' } });
  }, [signIn]);

  return <Redirecting />;
}
