import { type GetInsightsQuery } from '@/manager-graphql';

export function formatContact(
  contact: GetInsightsQuery['listInsights']['items'][number]['contact']
): { name: string; info: string } {
  return {
    name:
      [contact?.firstName, contact?.lastName].filter(Boolean).join(' ') ||
      'Anonymous',
    info: [contact?.email, contact?.phone].filter(Boolean).join(' - '),
  };
}

export function formatDateTime(dateString: string): string {
  const date = new Date(dateString);
  const formattedDate = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  });
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  return `${formattedDate} ${formattedTime}`;
}
