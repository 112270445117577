import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';

import type { GetInsightsQuery } from '@/manager-graphql';
import { useMeasurement } from '@/measurement';
import {
  Button,
  Chat,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerTitle,
  DrawerTrigger,
  StateMessageError,
  StateMessageLoading,
} from '@/shared-ui';

import { formatContact } from './insight-detail-item.helpers';
import { useGetMessagesQuery } from './insight-detail.hooks';

interface InsightDetailItemDrawerProps {
  children: React.ReactNode;
  insight: GetInsightsQuery['listInsights']['items'][number];
}

export default function InsightDetailItemDrawer({
  children,
  insight,
}: InsightDetailItemDrawerProps) {
  const [open, setOpen] = useState(false);
  const measurement = useMeasurement();
  const { data, error, fetchMore, loading } = useGetMessagesQuery({
    skip: !open,
    variables: { filter: { insightId: insight.id } },
  });
  const { name, info } = formatContact(insight.contact);

  useEffect(() => {
    if (open) {
      measurement?.track('View Full Conversation clicked');
    }
  }, [open, measurement]);

  const handleLoadMore = () => {
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          listMessages: {
            ...fetchMoreResult.listMessages,
            items: [
              ...prev.listMessages.items,
              ...fetchMoreResult.listMessages.items,
            ],
          },
        };
      },
      variables: {
        filter: { insightId: insight.id },
        after: data?.listMessages.pageInfo.endCursor,
      },
    });
  };

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>{children}</DrawerTrigger>

      <DrawerContent>
        <div className="flex justify-between bg-white p-8 pb-4">
          <div className="space-y-2">
            <DrawerTitle className="font-display text-2xl font-semibold leading-tight">
              {name}
            </DrawerTitle>

            <DrawerDescription className="text-sm leading-none text-zinc-500">
              {info}
            </DrawerDescription>
          </div>

          <DrawerClose className="p-4 text-zinc-500 hover:text-zinc-600">
            <FontAwesomeIcon icon={faXmark} />
          </DrawerClose>
        </div>

        <div className="flex flex-grow flex-col space-y-4 overflow-y-auto px-8 pb-6 pt-4">
          {loading ? (
            <StateMessageLoading />
          ) : error ? (
            <StateMessageError message={error.message} />
          ) : (
            <div className="rounded-md bg-neutral-100">
              <Chat
                messages={data?.listMessages.items ?? []}
                onLoadMore={
                  data?.listMessages.pageInfo.hasNextPage
                    ? handleLoadMore
                    : undefined
                }
              />
            </div>
          )}
        </div>

        <div className="flex justify-end rounded-bl-lg bg-white px-8 py-4">
          <DrawerClose asChild>
            <Button>Done</Button>
          </DrawerClose>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
