import {
  faHouseBlank,
  faMessagesQuestion,
  faSquarePollHorizontal,
} from '@fortawesome/pro-light-svg-icons';

import { useSidebarData } from './manager-sidebar.hooks';
import Sidebar from './manager-sidebar.sidebar';
import SidebarItem from './manager-sidebar.sidebar-item';

interface ManagerSidebarProps {
  children: React.ReactNode;
}

export default function ManagerSidebar({ children }: ManagerSidebarProps) {
  const { hasValidOrg, totalUnreadInsights } = useSidebarData();
  return (
    <div className="flex">
      <Sidebar>
        {hasValidOrg ? (
          <>
            <SidebarItem to="/home" icon={faHouseBlank} text="Home" />
            <SidebarItem
              to="/questions"
              icon={faMessagesQuestion}
              text="Questions"
            />
            <SidebarItem
              to="/insights"
              icon={faSquarePollHorizontal}
              text="Insights"
              alert={totalUnreadInsights || null}
            />
          </>
        ) : null}
      </Sidebar>

      {children}
    </div>
  );
}
