import { useAuth } from '@workos-inc/authkit-react';
import { Navigate } from 'react-router-dom';

import LandingHeader from './landing-header';
import LandingHero from './landing-hero';
import LandingTestimonials from './landing-testimonials';

export default function Landing() {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="home" />;
  }

  return (
    <>
      <LandingHeader />

      <main>
        <LandingHero />

        <LandingTestimonials />
      </main>
    </>
  );
}
