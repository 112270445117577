import { GET_COMPANY_INFO, REFRESH_COMPANY_INFO } from '@/manager-graphql';
import { useToast } from '@/manager-toast';
import { useMutation } from '@apollo/client';

export function useRefreshCompanyInfo() {
  const { addToast } = useToast();
  const [refreshCompanyInfo, { loading }] = useMutation(REFRESH_COMPANY_INFO, {
    onCompleted: () => {
      addToast({ title: 'Company summary refreshed', type: 'success' });
    },
    onError: (error) => {
      addToast({ title: error.message, type: 'error' });
    },
    update: (cache, { data }) => {
      if (data?.refreshCompanyInfo) {
        cache.modify({
          fields: {
            companyInfo() {
              return cache.writeQuery({
                data: { companyInfo: data.refreshCompanyInfo },
                query: GET_COMPANY_INFO,
              });
            },
          },
        });
      }
    },
  });

  return { refreshCompanyInfo, loading };
}
