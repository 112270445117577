import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Layout from '@/manager-sidebar';
import {
  PageHeader,
  StateMessageError,
  StateMessageLoading,
} from '@/shared-ui';

import DeleteUser from './user-management-delete-user';
import InviteUser from './user-management-invite-user';
import { MenuItem, MenuList } from './user-management-menu';
import ResendInvitation from './user-management-resend-invitation';
import RevokeInvitation from './user-management-revoke-invitation';
import {
  useDeleteUser,
  useResendInvitation,
  useRevokeInvitation,
  useUserManagement,
} from './user-management.hooks';

export default function UserManagement() {
  const { error, invitations, loading, users, onInviteUser } =
    useUserManagement();
  const { onDeleteUser, setUserToDelete, userToDelete } = useDeleteUser();
  const { invitationToResend, onResendInvitation, setInvitationToResend } =
    useResendInvitation();
  const { invitationToRevoke, onRevokeInvitation, setInvitationToRevoke } =
    useRevokeInvitation();

  return (
    <Layout>
      <div className="w-full bg-zinc-50">
        <PageHeader title="User Management" />

        <main className="space-y-6 px-10 py-6">
          {loading ? (
            <StateMessageLoading />
          ) : error ? (
            <StateMessageError message={error} />
          ) : (
            <>
              <div className="flex justify-end">
                <InviteUser onSubmit={onInviteUser} />
              </div>

              <div className="rounded-md border">
                <table className="w-full overflow-hidden rounded-md bg-white">
                  <thead className="bg-zinc-50">
                    <tr className="">
                      <th className="p-4 text-left text-sm font-medium leading-tight text-zinc-900">
                        Name
                      </th>
                      <th
                        colSpan={2}
                        className="p-4 text-left text-sm font-medium leading-tight text-zinc-900"
                      >
                        Email
                      </th>
                      <th className="p-4 text-center text-sm font-medium leading-tight text-zinc-900">
                        <FontAwesomeIcon icon={faChevronDown} />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {invitations.map((invitation) => (
                      <tr
                        className="border-t hover:bg-slate-50"
                        key={invitation.id}
                      >
                        <td className="p-4 text-sm font-medium leading-tight text-zinc-800"></td>
                        <td className="p-4 text-sm font-medium leading-tight text-zinc-500">
                          {invitation.email}
                        </td>
                        <td className="px-4 py-2 text-right">
                          <span className="rounded-3xl bg-orange-50 px-3 py-1 text-sm font-normal text-orange-500">
                            Pending
                          </span>
                        </td>
                        <td className="w-8 text-center">
                          <MenuList>
                            {/* TODO: Uncomment when implemented
                        <MenuItem
                          onClick={() => setInvitationToResend(invitation)}
                        >
                          Resend invite
                        </MenuItem> */}
                            <MenuItem
                              onClick={() => setInvitationToRevoke(invitation)}
                            >
                              Revoke invite
                            </MenuItem>
                          </MenuList>
                        </td>
                      </tr>
                    ))}

                    {users.map((user) => (
                      <tr className="border-t" key={user.id}>
                        <td className="p-4 text-sm font-medium leading-tight text-zinc-800">
                          {user.name}
                        </td>

                        <td
                          colSpan={2}
                          className="p-4 text-sm font-medium leading-tight text-zinc-500"
                        >
                          {user.email}
                        </td>

                        <td className="w-8 text-center">
                          {users.length > 1 ? (
                            <MenuList>
                              <MenuItem onClick={() => setUserToDelete(user)}>
                                Delete user
                              </MenuItem>
                            </MenuList>
                          ) : null}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </main>
      </div>

      <ResendInvitation
        invitation={invitationToResend}
        onCancel={() => setInvitationToResend(null)}
        onAction={onResendInvitation}
      />
      <RevokeInvitation
        invitation={invitationToRevoke}
        onCancel={() => setInvitationToRevoke(null)}
        onAction={onRevokeInvitation}
      />
      <DeleteUser
        user={userToDelete}
        onCancel={() => setUserToDelete(null)}
        onAction={onDeleteUser}
      />
    </Layout>
  );
}
