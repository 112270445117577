import {
  ADD_API_KEY,
  DELETE_API_KEY,
  GET_API_KEYS,
  GetApikeysQuery,
} from '@/manager-graphql';
import { useToast } from '@/manager-toast';
import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';

export const useGetApiKeys = () => {
  const [apiKeys, setApiKeys] = useState<GetApikeysQuery['listKeys']>([]);
  const { addToast } = useToast();
  const { data, loading, error, refetch } = useQuery(GET_API_KEYS, {
    fetchPolicy: 'cache-and-network',
  });

  const [addApikey] = useMutation(ADD_API_KEY);

  const [deleteApikey] = useMutation(DELETE_API_KEY, {
    refetchQueries: [GET_API_KEYS],
  });

  useEffect(() => {
    if (data?.listKeys) {
      setApiKeys(() => data.listKeys);
    }
  }, [data]);

  const handleAddApikey = useCallback(
    async (input: { name: string }) => {
      const response = await addApikey({
        variables: { input },
      });
      return response?.data?.addKey?.id;
    },
    [addApikey]
  );

  const handleDeleteApikey = async (id: string) => {
    // Remove the item from apiKeys
    let removedKey: GetApikeysQuery['listKeys'][0] | undefined;
    setApiKeys((prevApiKeys) => {
      const updatedApiKeys = prevApiKeys.filter((key) => {
        if (key.id === id) {
          removedKey = key;
          return false;
        }
        return true;
      });
      return updatedApiKeys;
    });

    try {
      // Call deleteApiKey
      await deleteApikey({
        variables: { id },
      });
      // If successful, console success
      addToast({
        title: 'API key deleted successfully',
        type: 'success',
      });
    } catch (error) {
      // If it fails, console.error and put the item back to where it was
      addToast({
        title: `Failed to delete API key: ${removedKey?.name}`,
        type: 'error',
      });
      setApiKeys((prevApiKeys) =>
        removedKey ? [...prevApiKeys, removedKey] : prevApiKeys
      );
    }
  };

  const handleCopyApikey = useCallback(
    (apikey: string) => {
      navigator.clipboard.writeText(apikey);
      addToast({ title: 'API key copied to clipboard', type: 'success' });
    },
    [addToast]
  );

  return {
    apiKeys: apiKeys || [],
    loading: apiKeys.length === 0 && loading,
    error: error?.message,
    handleAddApikey,
    handleDeleteApikey,
    handleCopyApikey,
    refetchKeys: refetch,
  };
};
