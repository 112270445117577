import { Rating } from '@/manager-graphql';
import classNames from 'classnames';

interface QuestionsSurveyRatingsPreviewProps {
  question: string;
  ratings: Rating[];
}

export const QuestionsSurveyRatingsPreview = ({
  question,
  ratings,
}: QuestionsSurveyRatingsPreviewProps) => (
  <div
    className={classNames(
      'shadow-base m-auto flex w-full flex-col rounded-lg border',
      {
        'p-4': ratings?.length === 5,
        'p-2': ratings?.length > 5,
      }
    )}
  >
    <div className="text-center text-sm font-bold">{question}</div>
    <span className="h-5" />
    <div className="flex w-full flex-wrap justify-center">
      {ratings?.map((rating) => (
        <button
          className="m-1 flex h-8 w-8 items-center justify-center rounded-lg border"
          key={rating?.id}
        >
          {rating?.value}
        </button>
      ))}
    </div>
    <span className="h-2" />
    <div className="flex w-full justify-between">
      <span className="text-sm/20 text-zinc-500">{ratings[0].label}</span>
      <span className="text-sm/20 text-zinc-500">
        {ratings[ratings.length - 1].label}
      </span>
    </div>
  </div>
);
