import { gql } from '../__generated__/gql';

export const RESEND_INVITATION = gql(/* GraphQL */ `
  mutation ResendInvitation($id: ID!) {
    resendInvitation(id: $id) {
      id
      email
    }
  }
`);
