import AlertDialog from './user-management-alert-dialog';
import type { Invitation } from './user-management.types';

interface ResendInvitationProps {
  invitation: Invitation | null;
  onAction(invitation: Invitation): Promise<void>;
  onCancel(): void;
}

export default function ResendInvitation({
  invitation,
  onAction,
  onCancel,
}: ResendInvitationProps) {
  const handleAction = async () => {
    if (invitation) {
      await onAction(invitation);
    } else {
      throw new Error('Invitation is required to resend.');
    }
  };

  return (
    <AlertDialog
      open={!!invitation}
      title="Resend the invite?"
      description={
        invitation?.email ? (
          <>
            Are you sure you want to resend the invite to{' '}
            <strong>{invitation.email}</strong>?
          </>
        ) : (
          'Are you sure you want to resend the invite?'
        )
      }
      action="Resend"
      successMessage="Invitation resent successfully!"
      errorMessage="There was an error resending invitation. Please try again."
      onCancel={onCancel}
      onAction={handleAction}
    />
  );
}
