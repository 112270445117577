import type { GetDashboardQuery } from '@/manager-graphql';

import { DashboardRatingsItem } from './dashboard-ratings-item';

type DashboardRatingsProps = Pick<
  GetDashboardQuery['dashboard'],
  'ratingDistribution'
>;

const DashboardRatings = ({ ratingDistribution }: DashboardRatingsProps) => {
  const sortedRatings = ratingDistribution
    ? [...ratingDistribution].sort((a, b) => b.score - a.score)
    : [];
  return (
    <section className="mt-6">
      <h3 className="text-sm font-medium text-zinc-700">Ratings</h3>

      <div className="mt-6 flex flex-col gap-4 rounded-lg border border-zinc-200 bg-white p-6 pt-6 shadow-sm hover:shadow">
        {sortedRatings.map((rating) => (
          <DashboardRatingsItem key={rating.score} rating={rating} />
        ))}
      </div>
    </section>
  );
};

export default DashboardRatings;
