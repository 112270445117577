import { useCallback } from 'react';

import {
  GetInsightsDocument,
  GetQuestionsWithStatsDocument,
} from '@/manager-graphql';

import InsightDetail from './insight-detail';
import {
  useDeleteInsight,
  useGetInsightsQuery,
  useGetQuestionsWithStats,
  useUpdateInsights,
} from './insight-detail.hooks';

interface InsightDetailContainerProps {
  questionId: string;
}

export default function InsightDetailContainer({
  questionId,
}: InsightDetailContainerProps) {
  const {
    data: insightsData,
    error: insightsError,
    loading: loadingInsights,
    fetchMore,
  } = useGetInsightsQuery({
    variables: { filter: { questionId } },
  });
  const [updateInsights] = useUpdateInsights();
  const [deleteInsight] = useDeleteInsight();

  const handleLoadMore = () => {
    fetchMore({
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          listInsights: {
            ...fetchMoreResult.listInsights,
            items: [
              ...prev.listInsights.items,
              ...fetchMoreResult.listInsights.items,
            ],
          },
        };
      },
      variables: {
        filter: { questionId },
        after: insightsData?.listInsights.pageInfo.endCursor,
      },
    });
  };

  const handleMarkAsRead = useCallback(
    async (insightIds: string[]) => {
      await updateInsights({
        refetchQueries: [GetQuestionsWithStatsDocument],
        variables: {
          input: {
            filter: { ids: insightIds },
            values: { msgRead: true },
          },
        },
      });
    },
    [updateInsights]
  );

  const handleDeleteInsight = useCallback(
    async (insightId: string) => {
      await deleteInsight({
        refetchQueries: [GetQuestionsWithStatsDocument, GetInsightsDocument],
        variables: {
          id: insightId,
        },
      });
    },
    [deleteInsight]
  );

  const {
    data: questionsData,
    error: questionsError,
    loading: loadingQuestions,
  } = useGetQuestionsWithStats();
  const question = questionsData?.listQuestions.items.find(
    (q) => q.id === questionId
  );
  if (!question) return null;

  return (
    <InsightDetail
      error={insightsError?.message || questionsError?.message}
      insights={insightsData?.listInsights.items ?? []}
      loading={loadingInsights || loadingQuestions}
      question={question}
      onLoadMore={
        insightsData?.listInsights.pageInfo.hasNextPage
          ? handleLoadMore
          : undefined
      }
      onMarkAsRead={handleMarkAsRead}
      onDelete={handleDeleteInsight}
    />
  );
}
