import { ApolloError } from '@apollo/client';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import { useToast } from '@/manager-toast';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/shared-ui';

interface AlertDialogProps {
  open: boolean;
  title: React.ReactNode;
  notification?: React.ReactNode;
  description: React.ReactNode;
  action: React.ReactNode;
  errorMessage: string;
  successMessage: string;
  onAction(): Promise<void>;
  onCancel(): void;
}

export default function AlertDialog({
  open,
  title,
  notification,
  description,
  action,
  errorMessage,
  successMessage,
  onAction,
  onCancel,
}: AlertDialogProps) {
  const { addToast } = useToast();
  const [isPending, setPending] = useState(false);

  const handleAction = async () => {
    setPending(true);
    try {
      await onAction();
      addToast({ title: successMessage, type: 'success' });
    } catch (error) {
      if (error instanceof ApolloError) {
        addToast({ title: error.message, type: 'error' });
      } else {
        addToast({ title: errorMessage, type: 'error' });
      }
    } finally {
      setPending(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onCancel}>
      <DialogContent>
        <DialogTitle className="font-display px-8 pt-8 text-2xl font-semibold leading-normal tracking-tight text-zinc-900">
          {title}
        </DialogTitle>

        {notification ? (
          <p className="font-display px-8 pt-4 text-sm font-normal leading-tight text-zinc-500">
            {notification}
          </p>
        ) : null}

        <DialogDescription className="font-display px-8 pb-6 pt-4 text-sm font-normal leading-tight text-zinc-500">
          {description}
        </DialogDescription>

        <div className="flex items-center justify-end border-t px-8 py-4">
          <div className="flex items-center gap-2">
            <DialogClose asChild>
              <Button color="secondary">Cancel</Button>
            </DialogClose>

            <Button onClick={handleAction} disabled={isPending}>
              {isPending ? <FontAwesomeIcon icon={faSpinner} spin /> : action}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
