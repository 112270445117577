import { faClose } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth } from '@workos-inc/authkit-react';

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
} from '@/shared-ui';
import { useUpgrade } from './manager-upgrade.hooks';

interface UpgradeStripeDialogProps {
  children?: React.ReactNode;
}

export const UpgradeStripeDialog = ({ children }: UpgradeStripeDialogProps) => {
  const { organizationId, user } = useAuth();
  const { showUpgrade, handleToggleUpgrade } = useUpgrade();
  return (
    <Dialog open={showUpgrade} onOpenChange={handleToggleUpgrade}>
      {children}
      <DialogContent className="max-h-full w-full max-w-5xl">
        <DialogTitle className="font-display pb-8 pt-8 text-center text-2xl font-semibold leading-tight">
          Select your plan
        </DialogTitle>
        <DialogClose asChild>
          <div className="absolute right-1 top-1">
            <Button color="text">
              <FontAwesomeIcon icon={faClose} />
            </Button>
          </div>
        </DialogClose>
        <div className="h-[835px]">
          <stripe-pricing-table
            pricing-table-id={import.meta.env.VITE_STRIPE_PRICING_TABLE_ID}
            publishable-key={import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY}
            customer-email={user?.email}
            client-reference-id={organizationId ?? undefined}
          ></stripe-pricing-table>
        </div>
      </DialogContent>
    </Dialog>
  );
};
