import { NavLink } from 'react-router-dom';

interface PageHeaderNavProps {
  items: Array<{
    name: string;
    to: React.ComponentProps<typeof NavLink>['to'];
  }>;
}

export const PageHeaderNav = ({ items }: PageHeaderNavProps) => {
  return (
    <nav className="flex gap-2 border-b border-s-zinc-200 bg-white px-10">
      {items.map((item) => (
        <NavLink
          key={item.name}
          to={item.to}
          className={({ isActive }) =>
            `px-4 py-3 text-center text-sm ${
              isActive
                ? 'font-semibold text-red-600 shadow-[inset_0px_-2px_0px_0px_#dd3131]'
                : 'text-zinc-500'
            }`
          }
        >
          {item.name}
        </NavLink>
      ))}
    </nav>
  );
};

export default PageHeaderNav;
