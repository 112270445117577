import { faPaperPlaneAlt } from '@fortawesome/pro-light-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import {
  type CompanyInfoFormValues,
  companyInfoFormSchema,
} from './company-info-form.helpers';

interface CompanyInfoFormProps {
  isInvalidWebsiteError: boolean;
  onSubmit: (data: CompanyInfoFormValues) => void;
}

export const CompanyInfoForm = ({
  isInvalidWebsiteError,
  onSubmit,
}: CompanyInfoFormProps) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    setFocus,
  } = useForm<CompanyInfoFormValues>({
    resolver: zodResolver(companyInfoFormSchema),
  });

  useEffect(() => {
    setFocus('website');
  }, [setFocus]);

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-4">
        {isInvalidWebsiteError ? (
          <>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-6xl text-yellow-500"
            />
            <h3 className="font-display text-center text-2xl font-semibold leading-tight text-zinc-800">
              Oops! It looks like that domain is invalid
            </h3>
            <p className="text-center leading-normal text-zinc-500">
              Please re-enter your company website or copy and paste it from the
              address bar in a window with your website open.
            </p>
          </>
        ) : (
          <>
            <img
              src="/img/evo.png"
              alt="Illustration of a stylized owl with large yellow eyes and gray feathers, perched on a red branch with a serious expression"
              className="h-20 w-20 rounded-lg bg-white shadow-lg"
            />
            <div className="flex flex-col items-center justify-center gap-2">
              <h1 className="font-display text-center text-2xl font-semibold leading-tight text-zinc-800">
                TheySaid Interactive Surveys
              </h1>
              <h3 className="text-center text-lg font-medium leading-normal text-red-600">
                Powered by Theysaid AI
              </h3>
            </div>
            <p className="text-center leading-normal text-zinc-500">
              Enter your website url below and Evo will generate a summary about
              your company. This summary will be used to generate questions to
              ask your customers
            </p>
          </>
        )}
      </div>

      <form onSubmit={handleSubmit(onSubmit)} className="relative">
        <input
          {...register('website')}
          className="w-full rounded-t-lg border border-b-0 p-4 text-sm leading-normal text-zinc-800 placeholder-zinc-500 transition-colors focus:outline-none"
          placeholder="Enter your company website to get started..."
        />
        <div className="flex w-full justify-end rounded-b-lg border border-t-0 bg-zinc-100 px-4 py-2">
          <button
            className="flex h-8 w-8 items-center justify-center rounded border bg-white pb-0.5 pl-0.5 text-zinc-800"
            type="submit"
            disabled={!!errors.website}
          >
            <FontAwesomeIcon icon={faPaperPlaneAlt} className="-rotate-45" />
          </button>
        </div>

        {errors.website?.message ? (
          <p className="absolute -bottom-6 px-2 text-sm text-red-600">
            {errors.website.message}
          </p>
        ) : null}
      </form>
    </>
  );
};

export default CompanyInfoForm;
