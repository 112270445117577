import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

const shouldMeasure =
  typeof window !== 'undefined' && window.location.hostname !== 'localhost';

export function useMixpanel(token: string) {
  useEffect(() => {
    if (token && shouldMeasure) {
      mixpanel.init(token, {
        debug: false,
        track_pageview: 'full-url',
        persistence: 'localStorage',
        autotrack: true,
      });
    }
  }, [token]);
}

export function useMeasurement() {
  return shouldMeasure ? mixpanel : undefined;
}

export function useMixpanelWithUser(
  token: string,
  user: {
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
  } | null
) {
  useMixpanel(token);
  useEffect(() => {
    if (shouldMeasure) {
      if (user) {
        const name = `${user.firstName || ''} ${user.lastName || ''}`.trim();
        mixpanel.identify(user.id);
        mixpanel.people.set({
          $email: user.email,
          ...(name ? { $name: name } : {}),
        });
      } else {
        mixpanel.get_property('$email') && mixpanel.reset();
      }
    }
  }, [user]);
}
