import classNames from 'classnames';

interface GaugeProps {
  value: number;
}

export const Gauge = ({ value }: GaugeProps) => {
  const remappedValue = +((value + 100) * (180 / 200)).toFixed(0);
  const rotation = `rotate-${remappedValue}`;

  return (
    <div className="flex flex-col items-center justify-center">
      <img src="/img/arc.svg" alt="Arc" />
      <img
        src="/img/pointer.svg"
        alt="Pointer"
        className={classNames('-mt-2', 'transform', rotation)}
        data-rotate={remappedValue}
      />
    </div>
  );
};
