import { faLinkSimple, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';

import type {
  AddSurveyMutationVariables,
  GetQuestionsQuery,
} from '@/manager-graphql';
import { useToast } from '@/manager-toast';
import { useMeasurement } from '@/measurement';
import {
  Button,
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerTitle,
  DrawerTrigger,
} from '@/shared-ui';

import {
  getHTMLEmbedCode,
  getHTMLiFrameCode,
  getSurveyUrl,
} from './questions-generate-links-drawer.helpers';
import QuestionsQRCodeDialog from './questions-qr-code-dialog';
import { QuestionsSurveyPreview } from './questions-survey-preview';

interface QuestionsGenerateLinksDrawerProps {
  onAddSurvey: (
    input: AddSurveyMutationVariables['input']
  ) => Promise<string | null>;
  question: GetQuestionsQuery['listQuestions']['items'][number];
}

type CodeType = 'htmlCode' | 'iframeCode' | 'shareLink' | 'qrCode';

export const QuestionsGenerateLinksDrawer = ({
  onAddSurvey,
  question: { id: questionId, primaryQuestion, ratings, tags },
}: QuestionsGenerateLinksDrawerProps) => {
  const [isSubmitting, setSubmitting] = useState<boolean>();
  const [surveyId, setSurveyId] = useState<string>();
  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const measurement = useMeasurement();
  const { addToast } = useToast();
  const closeRef = useRef<HTMLButtonElement>(null);

  const handleCopyHTML = async () => {
    if (surveyId) {
      try {
        await navigator.clipboard.writeText(
          await getHTMLEmbedCode(surveyId, primaryQuestion, ratings)
        );
        addToast({ title: 'HTML copied successfully!', type: 'success' });
        measurement?.track('Survey link copied', { format: 'HTML Embed code' });
      } catch (e) {
        addToast({
          title: 'Failed to generate Survey for HTML',
          type: 'error',
        });
      }
    }
  };

  const handleCopyIFrame = async () => {
    try {
      if (surveyId) {
        await navigator.clipboard.writeText(
          getHTMLiFrameCode(
            surveyId,
            tags?.some((t) => t.name?.toLowerCase()?.trim() === 'nps')
          )
        );
        addToast({ title: 'iFrame copied successfully!', type: 'success' });
        measurement?.track('Survey link copied', {
          format: 'iFrame Embed code',
        });
      } else {
        throw new Error('Failed to generate Survey for iFrame');
      }
    } catch (e) {
      addToast({
        title: 'Failed to generate Survey for iFrame',
        type: 'error',
      });
    }
  };

  const handleCopyShareableLink = async () => {
    try {
      if (surveyId) {
        await navigator.clipboard.writeText(getSurveyUrl(surveyId));
        addToast({
          title: 'Shareable Link copied successfully!',
          type: 'success',
        });
        measurement?.track('Survey link copied', { format: 'Shareable Link' });
      } else {
        throw new Error('Failed to generate Survey for Shareable Link');
      }
    } catch (e) {
      addToast({
        title: 'Failed to generate Survey for Shareable Link',
        type: 'error',
      });
    }
  };

  const handleGenerateQrCode = async () => {
    try {
      if (surveyId) {
        setQrCodeUrl(getSurveyUrl(surveyId));
        measurement?.track('Survey link copied', { format: 'QR Code' });
      } else {
        throw new Error('Failed to generate Survey for QR Code');
      }
    } catch (e) {
      addToast({
        title: 'Failed to generate Survey for QR Code',
        type: 'error',
      });
    }
  };

  const options = [
    {
      id: 'htmlCode' as CodeType,
      title: 'HTML Embed code',
      description:
        'Put a clickable survey into Klaviyo, Mailchimp, Hubspot, Zendesk and more',
      label: 'Copy HTML',
      onClick: handleCopyHTML,
    },
    {
      id: 'iframeCode' as CodeType,
      title: 'iFrame Embed code',
      description: 'Put a clickable survey onto your website',
      label: 'Copy iFrame',
      onClick: handleCopyIFrame,
    },
    {
      id: 'shareLink' as CodeType,
      title: 'Share a Link',
      description: 'Share a link with your customer via text or email',
      label: 'Get Shareable Link',
      onClick: handleCopyShareableLink,
    },
  ];

  const onDialogOpenChange = (open: boolean) => {
    if (questionId && !surveyId && open) {
      setSubmitting(() => true);
      onAddSurvey({ questionId })
        .then((surveyId) => {
          if (surveyId) {
            setSurveyId(() => surveyId);
          } else {
            closeRef?.current?.click();
            addToast({
              title: 'Failed to generate Survey',
              type: 'error',
            });
          }
        })
        .finally(() => {
          setSubmitting(() => false);
        });
    }
  };

  return (
    <Drawer onOpenChange={onDialogOpenChange}>
      <DrawerTrigger className="px-10">
        <FontAwesomeIcon
          className="text-emerald-600 transition-colors hover:text-emerald-800"
          icon={faLinkSimple}
        />
      </DrawerTrigger>

      <DrawerContent>
        <div className="flex justify-between overflow-hidden bg-white p-8 pb-4">
          <div className="mb-8 space-y-2">
            <DrawerTitle className="font-display text-2xl font-semibold leading-tight">
              Generate Links
            </DrawerTitle>

            <DrawerDescription className="text-sm leading-normal text-zinc-500">
              Embed an iFrame or HTML code
            </DrawerDescription>
          </div>

          <DrawerClose className="p-4 text-zinc-500 hover:text-zinc-600">
            <FontAwesomeIcon icon={faXmark} />
          </DrawerClose>
        </div>

        <div className="flex flex-grow flex-col space-y-4 overflow-y-auto px-8 pb-6 pt-4">
          <h4 className="font-semibold leading-normal text-zinc-500">
            Instructions:
          </h4>
          <p className="text-sm leading-normal text-zinc-500">
            TheySaid offers multiple ways to share your surveys with your
            customers. A variety of formats are provided by clicking the
            appropriate link below.
          </p>
          <p className="text-sm leading-normal text-zinc-500">
            You may insert the iFrame or HTML code onto your website or into
            your email platform of choice. Shareable Links are also provided for
            SMS or email hyperlinks. QR Codes may be used on signage or at
            events to collect feedback.
          </p>

          <div className="text-sm leading-normal">
            <QuestionsSurveyPreview
              question={primaryQuestion}
              ratings={ratings}
            />
          </div>

          <div className="divide-y">
            {options.map((item) => (
              <div
                key={item.title}
                className="flex items-center justify-between py-4"
              >
                <div className="max-w-80">
                  <h4 className="font-semibold leading-normal text-zinc-500">
                    {item.title}
                  </h4>
                  <p className="text-sm leading-normal text-zinc-500">
                    {item.description}
                  </p>
                </div>

                <Button
                  color="secondary"
                  disabled={isSubmitting}
                  onClick={item.onClick}
                >
                  {item.label}
                </Button>
              </div>
            ))}

            <div className="flex items-center justify-between py-4">
              <div className="max-w-80">
                <h4 className="font-semibold leading-normal text-zinc-500">
                  QR Code
                </h4>
                <p className="text-sm leading-normal text-zinc-500">
                  Share a QR code with your customers on signs or at events
                </p>
              </div>

              <QuestionsQRCodeDialog
                value={qrCodeUrl}
                onClose={() => setQrCodeUrl('')}
                onGenerateQrCode={handleGenerateQrCode}
                isSubmitting={isSubmitting}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-end rounded-bl-lg bg-white px-8 py-4">
          <DrawerClose asChild>
            <Button ref={closeRef}>Done</Button>
          </DrawerClose>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default QuestionsGenerateLinksDrawer;
