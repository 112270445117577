import AlertDialog from './user-management-alert-dialog';
import type { User } from './user-management.types';

interface DeleteUserProps {
  user: User | null;
  onAction(user: User): Promise<void>;
  onCancel(): void;
}

export default function DeleteUser({
  user,
  onAction,
  onCancel,
}: DeleteUserProps) {
  const handleAction = async () => {
    if (user) {
      await onAction(user);
    } else {
      throw new Error('User is required to delete.');
    }
  };

  return (
    <AlertDialog
      open={!!user}
      title="Delete user?"
      notification="This action cannot be undone and will permanently remove all associated data."
      description={
        user?.email ? (
          <>
            Are you sure you want to delete <strong>{user.email}</strong>?
          </>
        ) : (
          'Are you sure you want to delete this user?'
        )
      }
      action="Delete"
      successMessage="User deleted successfully!"
      errorMessage="There was an error deleting user. Please try again."
      onCancel={onCancel}
      onAction={handleAction}
    />
  );
}
