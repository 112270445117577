import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';

import {
  DELETE_USER,
  GET_USER_MANAGEMENT_DATA,
  INVITE_USER,
  RESEND_INVITATION,
  REVOKE_INVITATION,
} from '@/manager-graphql';

import type { Invitation, User } from './user-management.types';

export function useUserManagement() {
  const { data, loading, error } = useQuery(GET_USER_MANAGEMENT_DATA, {
    variables: { first: 100 },
  });
  const [inviteUser] = useMutation(INVITE_USER, {
    refetchQueries: [GET_USER_MANAGEMENT_DATA],
  });

  const handleInviteUser = async (input: { email: string }) => {
    await inviteUser({ variables: { input } });
  };

  const invitations = data?.invitations.items || [];
  const users =
    data?.users.items.map((user) => ({
      id: user.id,
      name: [user.firstName, user.lastName].filter(Boolean).join(' '),
      email: user.email,
    })) || [];

  return {
    loading,
    error: error?.message,
    invitations,
    users,
    onInviteUser: handleInviteUser,
  };
}

export function useDeleteUser() {
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: [GET_USER_MANAGEMENT_DATA],
  });

  const handleDeleteUser = async ({ id }: User) => {
    return deleteUser({
      variables: { id },
      onCompleted: () => setUserToDelete(null),
    });
  };

  return {
    userToDelete,
    onDeleteUser: handleDeleteUser,
    setUserToDelete,
  };
}

export function useResendInvitation() {
  const [invitationToResend, setInvitationToResend] =
    useState<Invitation | null>(null);
  const [resendInvitation] = useMutation(RESEND_INVITATION);

  const handleResendInvitation = async ({ id }: Invitation) => {
    return resendInvitation({
      variables: { id },
      onCompleted: () => setInvitationToResend(null),
    });
  };

  return {
    invitationToResend,
    onResendInvitation: handleResendInvitation,
    setInvitationToResend,
  };
}

export function useRevokeInvitation() {
  const [invitationToRevoke, setInvitationToRevoke] =
    useState<Invitation | null>(null);
  const [revokeInvitation] = useMutation(REVOKE_INVITATION, {
    refetchQueries: [GET_USER_MANAGEMENT_DATA],
  });

  const handleRevokeInvitation = async ({ id }: Invitation) => {
    return revokeInvitation({
      variables: { id },
      onCompleted: () => setInvitationToRevoke(null),
    });
  };

  return {
    invitationToRevoke,
    onRevokeInvitation: handleRevokeInvitation,
    setInvitationToRevoke,
  };
}
