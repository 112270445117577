import { faRefresh, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from '@/shared-ui';
import { useRefreshCompanyInfo } from './company-summary-refresh.hooks';

export default function CompanySummaryRefresh() {
  const { refreshCompanyInfo, loading } = useRefreshCompanyInfo();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpenChange = (open: boolean) => {
    setSearchParams((prev) => {
      const params = new URLSearchParams(prev);
      if (open) {
        params.set('refresh', 'open');
      } else {
        params.delete('refresh');
      }
      return params;
    });
  };

  const handleRefresh = async () => {
    await refreshCompanyInfo();
    handleOpenChange(false);
  };

  return (
    <Dialog
      open={searchParams.get('refresh') === 'open'}
      onOpenChange={handleOpenChange}
    >
      <DialogTrigger asChild>
        <button
          color="text"
          className="invisible flex h-8 w-8 items-center justify-center rounded text-zinc-500 transition-colors hover:bg-zinc-100 group-hover:visible"
        >
          <FontAwesomeIcon icon={faRefresh} />
        </button>
      </DialogTrigger>

      <DialogContent>
        <DialogTitle className="font-display px-8 pt-8 text-2xl font-semibold leading-normal tracking-tight text-zinc-900">
          Company Summary Refresh
        </DialogTitle>

        <p className="font-display px-8 pt-4 text-sm font-normal leading-tight text-zinc-500">
          This action cannot be undone and will permanently replace all company
          summary information previous saved. It may take a few minutes to
          complete.
        </p>

        <DialogDescription className="font-display px-8 pb-6 pt-4 text-sm font-normal leading-tight text-zinc-500">
          Are you sure you want to refresh the company summary?
        </DialogDescription>

        <div className="flex items-center justify-end border-t px-8 py-4">
          <div className="flex items-center gap-2">
            <DialogClose asChild>
              <Button color="secondary" disabled={loading}>
                Cancel
              </Button>
            </DialogClose>

            <Button onClick={handleRefresh} disabled={loading}>
              {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Refresh'}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
