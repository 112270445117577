import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

import { useEffect } from 'react';
import {
  addCustomQuestionSchema,
  type AddCustomQuestionFormValues,
} from './questions-add-custom-question-form.helpers';

interface QuestionsAddFormProps {
  onSubmit: (values: AddCustomQuestionFormValues) => void;
}

export const AddCustomQuestionForm = ({ onSubmit }: QuestionsAddFormProps) => {
  const {
    formState: { isSubmitSuccessful, isSubmitting, isValid },
    handleSubmit,
    register,
    reset,
  } = useForm<AddCustomQuestionFormValues>({
    resolver: zodResolver(addCustomQuestionSchema),
  });

  useEffect(() => {
    reset();
  }, [isSubmitSuccessful, reset]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="sticky bottom-0 border-t bg-white px-10 py-6"
    >
      <div className="relative rounded-lg border p-4">
        <div className="flex gap-2">
          <input
            {...register('question')}
            className="w-full text-sm leading-normal text-zinc-800 placeholder-zinc-500 transition-colors focus:outline-none"
            placeholder="Write your own question here"
          />

          <button
            type="submit"
            disabled={isSubmitting || !isValid}
            className="text-nowrap rounded-md bg-red-500 px-4 py-2 text-sm font-medium leading-normal text-white transition-colors enabled:hover:bg-red-600 disabled:opacity-50"
          >
            Add your Custom Question
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddCustomQuestionForm;
