import {
  faChevronLeft,
  faCircleInfo,
  faSpinner,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { InView } from 'react-intersection-observer';
import { Link, useNavigate } from 'react-router-dom';

import Layout from '@/manager-sidebar';
import {
  Gauge,
  PageHeader,
  StateMessageError,
  StateMessageLoading,
  Tooltip,
} from '@/shared-ui';

import type { GetInsightsQuery } from '@/manager-graphql';
import InsightDetailItem from './insight-detail-item';
import type InsightListItem from './insight-list-item';
import { isNPSQuestion } from './insight-list-item.helpers';

interface InsightDetailProps {
  error?: string;
  insights: GetInsightsQuery['listInsights']['items'];
  loading: boolean;
  question: React.ComponentProps<typeof InsightListItem>;
  onLoadMore?: () => void;
  onMarkAsRead: (insightIds: string[]) => void;
  onDelete?: (insightId: string) => Promise<void>;
}

export default function InsightDetail({
  error,
  insights,
  loading,
  question: { primaryQuestion, ...statistics },
  onLoadMore,
  onMarkAsRead,
  onDelete,
}: InsightDetailProps) {
  const navigate = useNavigate();
  const parsedStats = [
    {
      name: 'Insights',
      value: statistics.totalInsights,
      tooltip: 'Total number of survey responses',
    },
    isNPSQuestion(statistics.tags)
      ? {
          name: 'NPS Score',
          value: statistics.avgScore?.toFixed(0),
          tooltip: 'NPS score provided by survey responders',
          showGauge: true,
        }
      : {
          name: 'Avg Score',
          value: statistics.avgScore?.toFixed(1),
          tooltip: 'Average  numeric score provided by survey responders',
        },
    {
      name: 'Negative customer signals',
      value: statistics.totalRiskSignals,
    },
    {
      name: 'Sales opportunity signals',
      value: statistics.totalGrowthSignals,
    },
    {
      name: 'Conversation Depth',
      value: statistics.avgMessageDepth?.toFixed(1),
      tooltip:
        'Median number of back and forth interactions including the initial response.',
    },
  ];

  useEffect(() => {
    if (insights.length) {
      const unreadIds = insights.reduce((acc, insight) => {
        if (!insight.msgRead) {
          acc.push(insight.id);
        }
        return acc;
      }, [] as string[]);
      if (unreadIds.length) {
        onMarkAsRead(unreadIds);
      }
    }
  }, [insights, onMarkAsRead]);

  useEffect(() => {
    if (insights.length === 0 && !loading && statistics.totalInsights) {
      navigate(-1);
    }
  }, [insights, loading, navigate, statistics.totalInsights]);

  return (
    <Layout>
      <div className="w-full bg-zinc-50">
        <PageHeader title="Insights">
          {!loading ? (
            <div className="flex flex-col gap-4 bg-white px-10 py-8">
              <div>
                <Link
                  to=".."
                  relative="path"
                  className="flex items-center text-sm leading-normal text-zinc-700"
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className="mr-1 text-xs"
                  />
                  Back
                </Link>
              </div>

              <div>
                <h2 className="font-display text-2xl font-semibold leading-tight">
                  {primaryQuestion}
                </h2>
              </div>

              <div className="flex flex-row gap-4">
                {parsedStats.map((item) => (
                  <div
                    key={item.name}
                    className="flex basis-1/5 justify-between rounded-lg border border-zinc-200 bg-white p-4 shadow-sm hover:shadow"
                  >
                    <div className="flex flex-col justify-between">
                      <span className="text-xs font-medium leading-tight text-zinc-500">
                        {item.name}{' '}
                        {item.tooltip && (
                          <Tooltip content={item.tooltip}>
                            <FontAwesomeIcon
                              className="text-zinc-500 transition-colors hover:text-emerald-600"
                              icon={faCircleInfo}
                            />
                          </Tooltip>
                        )}
                      </span>
                      <span className="font-display text-2xl font-semibold leading-tight text-zinc-800">
                        {item.value}
                      </span>
                    </div>
                    {item?.showGauge && <Gauge value={+(item?.value || '0')} />}
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </PageHeader>

        <main className="px-10 py-6">
          {loading ? (
            <StateMessageLoading />
          ) : error ? (
            <StateMessageError message={error} />
          ) : (
            <ul className="flex flex-col gap-2">
              {insights.map((insight) => (
                <li key={insight.id}>
                  <InsightDetailItem insight={insight} onDelete={onDelete} />
                </li>
              ))}
              {onLoadMore ? (
                <InView as="div" onChange={onLoadMore} className="mx-auto mt-4">
                  <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
                </InView>
              ) : null}
            </ul>
          )}
        </main>
      </div>
    </Layout>
  );
}
