import Layout from '@/manager-sidebar';
import {
  PageHeader,
  PageHeaderNav,
  StateMessageError,
  StateMessageLoading,
} from '@/shared-ui';
import classNames from 'classnames';
import Markdown from 'react-markdown';
import rehypeExtLinks from 'rehype-external-links';
import remarkGfm from 'remark-gfm';
import { useApiAccessDocs } from './manager-api-access-docs.hooks';

export function ApiAccessDocs() {
  const { apiDocs, loading, error, handlePageChange, currentPage } =
    useApiAccessDocs();
  return (
    <Layout>
      <div className="w-full bg-zinc-50">
        <PageHeader title="API Access">
          <PageHeaderNav
            items={[
              { name: 'API Keys', to: '/api-access/keys' },
              { name: 'API Documentation', to: '/api-access/docs' },
            ]}
          />
        </PageHeader>

        <main className="space-y-6 px-10 py-6">
          {loading ? (
            <StateMessageLoading />
          ) : error ? (
            <StateMessageError message={error} />
          ) : (
            <div className="flex items-start justify-between gap-10">
              <div className="p2 flex flex-col rounded-md border bg-white">
                {apiDocs?.pages?.map((page) => (
                  <span
                    key={page.id}
                    className={classNames(
                      'text-md cursor-pointer whitespace-nowrap p-4 text-zinc-900 hover:bg-gray-100',
                      {
                        'bg-gray-100': currentPage?.id === page.id,
                      }
                    )}
                    onClick={() => handlePageChange(page.id)}
                  >
                    {page.name}
                  </span>
                ))}
              </div>
              <div className="flex w-full flex-col justify-start rounded-md border bg-white p-5 align-top">
                <Markdown
                  rehypePlugins={[
                    [rehypeExtLinks, { target: '_blank' }],
                    [remarkGfm],
                  ]}
                  className="prose w-full max-w-full"
                >
                  {currentPage?.content?.replace(/---/g, ':---')}
                </Markdown>
              </div>
            </div>
          )}
        </main>
      </div>
    </Layout>
  );
}

export default ApiAccessDocs;
