import { useMutation } from '@apollo/client';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Indicator, Root, type CheckboxProps } from '@radix-ui/react-checkbox';

import { UPDATE_INSIGHT } from '@/manager-graphql';

export default function InsightFollowup({ id, checked }: CheckboxProps) {
  const htmlFor = `insight-followup-${id}`;
  const [updateInsight] = useMutation(UPDATE_INSIGHT);
  const handleFollowupChange = async (followup: boolean) => {
    await updateInsight({ variables: { input: { id, followup } } });
  };

  return (
    <div className="flex items-center gap-2">
      <Root
        checked={checked}
        className="flex h-4 w-4 items-center justify-center rounded border border-zinc-200 bg-white hover:bg-zinc-200 data-[state=checked]:border-red-600 data-[state=checked]:bg-red-600 hover:data-[state=checked]:border-red-800 hover:data-[state=checked]:bg-red-800"
        id={htmlFor}
        onCheckedChange={handleFollowupChange}
      >
        <Indicator className="text-xs text-white">
          <FontAwesomeIcon icon={faCheck} />
        </Indicator>
      </Root>

      <label
        className="text-sm font-normal leading-tight text-zinc-900"
        htmlFor={htmlFor}
      >
        Follow Up
      </label>
    </div>
  );
}
