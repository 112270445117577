import { z } from 'zod';

export const URL_PATTERN =
  /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

export const companyInfoFormSchema = z.object({
  website: z
    .string()
    .transform((value) =>
      value.startsWith('http') ? value : ['https://', value].join('')
    )
    .refine((transformedValue) => URL_PATTERN.test(transformedValue), {
      message: 'Invalid url. Please enter a valid url.',
    }),
});

export type CompanyInfoFormValues = z.infer<typeof companyInfoFormSchema>;
