import { useMutation, useQuery, type QueryHookOptions } from '@apollo/client';
import { addDays, subMonths } from 'date-fns';
import { atom, useRecoilState } from 'recoil';

import {
  GENERATE_DASHBOARD_SUMMARY,
  GET_DASHBOARD,
  type GetDashboardQuery,
  type GetDashboardQueryVariables,
} from '@/manager-graphql';

const dashboardFilterState = atom({
  key: 'dashboardFilter',
  default: {
    since: subMonths(new Date(), 1),
    until: addDays(new Date(), 1),
  },
});

export const useDashboardFilter = () => useRecoilState(dashboardFilterState);

export const useGetDashboard = (
  options: QueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables> = {}
) => useQuery(GET_DASHBOARD, options);

export const useGenerateDashboardSummary = () =>
  useMutation(GENERATE_DASHBOARD_SUMMARY);
