import { z } from 'zod';

export const parseInsightDetailPageParams = (
  params: { id?: string } = {}
): string | null => {
  try {
    return z.string().uuid().parse(params?.id);
  } catch {
    return null;
  }
};
