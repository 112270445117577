import { useMutation } from '@apollo/client';

import { GET_COMPANY_INFO, UPDATE_COMPANY_INFO } from '@/manager-graphql';

export { useGetCompanyInfo } from './company-summary.hooks';

export const useUpdateCompanyInfo = () =>
  useMutation(UPDATE_COMPANY_INFO, {
    refetchQueries: [GET_COMPANY_INFO],
  });
