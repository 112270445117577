import type { GetDashboardQuery } from '@/manager-graphql';
import Layout from '@/manager-sidebar';
import {
  PageHeader,
  PageHeaderNav,
  StateMessageError,
  StateMessageLoading,
} from '@/shared-ui';

import DashboardEmptyState from './dashboard-empty-state';
import DashboardRatings from './dashboard-ratings';
import DashboardStatistics from './dashboard-statistics';
import DashboardSummary from './dashboard-summary';

interface DashboardProps
  extends Pick<
    React.ComponentProps<typeof DashboardSummary>,
    'onGenerateSummary'
  > {
  dashboard?: GetDashboardQuery['dashboard'];
  error?: string;
  loadingDashboard: boolean;
  loadingSummary: boolean;
}

export const Dashboard = ({
  dashboard,
  error,
  loadingDashboard,
  loadingSummary,
  onGenerateSummary,
}: DashboardProps) => {
  return (
    <Layout>
      <div className="w-full bg-zinc-50">
        <PageHeader title="Theysaid AI Company Summary">
          <PageHeaderNav
            items={[
              { name: 'Dashboard', to: '/home/dashboard' },
              { name: 'Company Summary', to: '/home/company-summary' },
            ]}
          />
        </PageHeader>

        <main className="space-y-6 px-10 py-6">
          {loadingDashboard ? (
            <StateMessageLoading />
          ) : error ? (
            <StateMessageError message={error} />
          ) : !dashboard || !dashboard.totalInsights ? (
            <DashboardEmptyState />
          ) : (
            <>
              <DashboardStatistics {...dashboard} />

              <DashboardSummary
                loading={loadingSummary}
                summary={dashboard.summary}
                onGenerateSummary={onGenerateSummary}
              />

              <DashboardRatings
                ratingDistribution={dashboard.ratingDistribution}
              />
            </>
          )}
        </main>
      </div>
    </Layout>
  );
};

export default Dashboard;
