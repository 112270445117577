import { faDownload } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogDescription } from '@radix-ui/react-dialog';
import { useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';

import { useToast } from '@/manager-toast';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
} from '@/shared-ui';
import { useCopyToClipboard } from './questions.hooks';

interface SurveyQRCodeDialogProps {
  onClose: () => void;
  onGenerateQrCode: () => void;
  value: string;
  isSubmitting?: boolean;
}

const SurveyQRCodeDialog = ({
  onClose,
  onGenerateQrCode,
  value,
  isSubmitting,
}: SurveyQRCodeDialogProps) => {
  const { addToast } = useToast();
  const ref = useRef<HTMLDivElement>(null);
  const [imageBlob, setImageBlob] = useState<Blob | null>(null);
  const copyToClipboard = useCopyToClipboard();

  const handleCopyShareableLink = async () => {
    await navigator.clipboard.writeText(value);
    addToast({
      title: 'Shareable Link copied successfully!',
      type: 'success',
    });
  };

  const handleCopyImage = async () => {
    if (!imageBlob) {
      addToast({
        title: 'Failed to copy image',
        type: 'error',
      });
      return;
    }

    await copyToClipboard(imageBlob)
      .then(() => {
        addToast({ title: 'Image copied successfully!', type: 'success' });
      })
      .catch(() => {
        addToast({ title: 'Failed to copy image', type: 'error' });
      });
  };

  /**
   * I ended up implementing a work around to copy the image to the clipboard because Safari works differently
   * then other browsers. You cannot call `navigator.clipboard.write` with a blob directly in Safari. It should
   * be run in user action context.
   *
   * ref.current is null and MutationObserver to watch for children is not working as expected
   * https://app.clickup.com/t/86898epnn
   */
  useEffect(() => {
    const handle = setInterval(() => {
      if (ref.current && ref.current.querySelector('svg')) {
        const svgElement = ref.current?.querySelector('svg') as SVGElement;

        if (svgElement) {
          const serializer = new XMLSerializer();
          const svgString = serializer.serializeToString(svgElement);

          // Create a data URL representing the SVG image
          const svgDataUrl = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
            svgString
          )}`;

          // Create a temporary image element
          const img = document.createElement('img');
          img.src = svgDataUrl;

          // Wait for the image to load
          img.onload = () => {
            // Create a canvas element to draw the image onto
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);

            // Copy the canvas content to the clipboard
            canvas.toBlob((blob) => {
              setImageBlob(() => blob);
            });
          };
        }
        clearInterval(handle);
      }
    }, 1000);
    return () => clearInterval(handle);
  }, []);

  const handleDownloadImage = () => {
    if (!imageBlob) {
      addToast({
        title: 'Failed to download image',
        type: 'error',
      });
      return;
    }

    const url = URL.createObjectURL(imageBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'qr-code.png';
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <Dialog open={!!value} onOpenChange={onClose}>
      <Button
        onClick={onGenerateQrCode}
        color="secondary"
        disabled={isSubmitting}
      >
        Generate QR Code
      </Button>

      <DialogContent>
        <DialogTitle className="font-display px-8 pt-8 text-2xl font-semibold leading-tight">
          QR Code
        </DialogTitle>
        <DialogDescription className="invisible">
          QR Code for the survey
        </DialogDescription>

        <div className="px-8 py-6" ref={ref}>
          <QRCode value={value} className="mx-auto" />
        </div>

        <div className="flex items-center justify-between border-t px-8 py-4">
          <DialogClose asChild>
            <Button color="text">Cancel</Button>
          </DialogClose>

          <div className="flex items-center gap-1">
            <Button onClick={handleDownloadImage} color="text">
              <FontAwesomeIcon icon={faDownload} />
            </Button>
            <Button color="text" onClick={handleCopyShareableLink}>
              Copy link
            </Button>
            <Button onClick={handleCopyImage}>Copy image</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SurveyQRCodeDialog;
