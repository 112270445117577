import { faPlus, faTrashCan } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useFieldArray, useForm, type Control } from 'react-hook-form';

import { GetCompanyInfoQuery } from '@/manager-graphql';
import { Button } from '@/shared-ui';

import { useToast } from '@/manager-toast';
import {
  updateCompanyInfoSchema,
  type UpdateCompanyInfoFormValues,
} from './company-summary-form.helpers';
import CompanySummaryProductServicesFormSection from './company-summary-products-services-form-section';

interface CompanySummaryFormProps {
  companyInfo?: GetCompanyInfoQuery['companyInfo'];
  onSubmit: (values: UpdateCompanyInfoFormValues) => void;
}

export const CompanySummaryForm = ({
  companyInfo,
  onSubmit,
}: CompanySummaryFormProps) => {
  const { addToast } = useToast();
  const {
    control,
    formState: { errors, isSubmitSuccessful, isSubmitting },
    handleSubmit,
    register,
  } = useForm<UpdateCompanyInfoFormValues>({
    defaultValues: updateCompanyInfoSchema.parse(companyInfo),
    resolver: zodResolver(updateCompanyInfoSchema),
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'productServices',
    rules: { minLength: 1 },
  });

  useEffect(() => {
    if (isSubmitSuccessful) {
      addToast({
        title: 'Company Summary updated successfully',
        type: 'success',
      });
    }
  }, [addToast, isSubmitSuccessful]);

  const handleAddProductServices = () => {
    append({ name: '', benefits: [], features: [], uniqueSellingPoints: [] });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6 rounded-lg border bg-white p-6">
        <div className="space-y-1">
          <h2 className="text-sm font-semibold leading-normal text-zinc-800">
            Company Info
          </h2>
          <p className="text-sm leading-normal text-zinc-500">
            Edit your company information below, be sure to Save Changes when
            you're done.
          </p>
        </div>

        <div className="space-y-4">
          <div className="flex flex-col justify-between gap-4 md:flex-row">
            {[
              { label: 'Company name', disabled: true, field: 'name' as const },
              {
                label: 'Website URL',
                disabled: true,
                field: 'website' as const,
              },
              { label: 'Category', field: 'category' as const },
            ].map(({ label, disabled, field }) => (
              <div className="grow" key={field}>
                <label
                  className="block text-xs font-semibold leading-normal tracking-wide text-zinc-500"
                  htmlFor={field}
                >
                  {label}
                </label>
                <input
                  data-invalid={errors[field]?.message ? true : false}
                  disabled={disabled}
                  className="w-full rounded border px-2 py-1 text-sm leading-normal text-zinc-800 focus:outline-none disabled:opacity-75 data-[invalid=true]:border-red-500"
                  id={field}
                  {...register(field)}
                />
              </div>
            ))}
          </div>

          <div>
            <label
              className="block text-xs font-semibold leading-normal tracking-wide text-zinc-500"
              htmlFor="description"
            >
              Description
            </label>
            <textarea
              data-invalid={errors.description?.message ? true : false}
              className="w-full resize-none rounded border px-2 py-1 text-sm leading-normal text-zinc-800 focus:outline-none data-[invalid=true]:border-red-500"
              id="description"
              rows={5}
              {...register('description')}
            />
          </div>
        </div>

        <hr />

        <div className="space-y-4">
          <div className="space-y-1">
            <h2 className="text-sm font-semibold leading-normal text-zinc-800">
              Products and/or Services
            </h2>
            <p className="text-sm leading-normal text-zinc-500">
              Add or edit your products and/or services below, be sure to Save
              Changes when you're done.
            </p>
          </div>

          {fields.map((field, index) => (
            <div key={field.id} className="space-y-2 rounded-lg bg-zinc-50 p-4">
              <div className="flex items-baseline justify-between">
                <label
                  htmlFor={`productServices.${index}.name`}
                  className="text-sm font-semibold leading-normal text-zinc-800"
                >
                  Product or Service
                </label>
                <button
                  className="flex h-8 w-8 items-center justify-center rounded text-zinc-500 transition-colors hover:bg-zinc-100"
                  onClick={() => remove(index)}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              </div>

              <input
                data-invalid={!!errors.productServices?.[index]?.name?.message}
                className="w-full rounded border px-2 py-1 text-sm leading-normal text-zinc-800 focus:outline-none data-[invalid=true]:border-red-500"
                id={`productServices.${index}.name`}
                {...register(`productServices.${index}.name`)}
              />

              <div className="pt-4">
                <h3 className="text-sm font-semibold leading-normal text-zinc-800">
                  Definitions
                </h3>
              </div>

              {(['features', 'benefits', 'uniqueSellingPoints'] as const).map(
                (fieldName) => (
                  <CompanySummaryProductServicesFormSection
                    key={fieldName}
                    control={control as unknown as Control}
                    errors={errors.productServices}
                    fieldName={fieldName}
                    index={index}
                    register={register}
                  />
                )
              )}
            </div>
          ))}

          <Button color="text" onClick={handleAddProductServices}>
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Product Services
          </Button>
        </div>
      </div>

      <div className="mt-8 text-right">
        <Button disabled={isSubmitting}>Save Changes</Button>
      </div>
    </form>
  );
};

export default CompanySummaryForm;
