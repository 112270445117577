import { faChevronLeft, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InView } from 'react-intersection-observer';

import type { GetQuestionsQuery } from '@/manager-graphql';

import QuestionsAccordionItem from './questions-accordion-item';

interface QuestionsAccordionProps
  extends Omit<
    React.ComponentProps<typeof QuestionsAccordionItem>,
    'question'
  > {
  groupName: string;
  questions: GetQuestionsQuery['listQuestions']['items'];
  onLoadMore?: () => void;
}

const QuestionsAccordion = ({
  groupName,
  questions,
  onLoadMore,
  ...rest
}: QuestionsAccordionProps) => {
  return (
    <details open className="group rounded-md border bg-zinc-100">
      <summary className="flex items-center justify-between px-6 py-3 marker:content-none hover:cursor-pointer group-open:border-b ">
        <span className="text-sm font-medium leading-normal">{groupName}</span>
        <div className="flex items-center gap-4">
          <span className="text-sm font-medium leading-normal">Get Link</span>
          <FontAwesomeIcon
            className="text-xs text-zinc-500 transition-transform group-open:-rotate-90"
            icon={faChevronLeft}
          />
        </div>
      </summary>

      <ul className="grid grid-cols-1 divide-y rounded-b-md">
        {questions.map((question) => (
          <QuestionsAccordionItem
            {...rest}
            key={question.id}
            question={question}
          />
        ))}
        {onLoadMore ? (
          <InView
            as="div"
            className="rounded-b-md bg-white py-2 text-center text-zinc-500"
            onChange={onLoadMore}
          >
            <FontAwesomeIcon icon={faSpinner} spin />
          </InView>
        ) : null}
      </ul>
    </details>
  );
};

export default QuestionsAccordion;
