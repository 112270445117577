import { z } from 'zod';

export const updateQuestionSchema = z.object({
  id: z.string().uuid(),
  primaryQuestion: z
    .string()
    .trim()
    .min(1, { message: 'Question is required' }),
});

export type UpdateQuestionFormValues = z.infer<typeof updateQuestionSchema>;
