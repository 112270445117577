import InsightList from './insight-list';
import { useGetQuestionsWithStats } from './insight-list.hooks';

export const InsightListContainer = () => {
  const { data, error, loading } = useGetQuestionsWithStats();

  return (
    <InsightList
      error={error?.message}
      loading={loading}
      questions={
        data?.listQuestions.items?.filter(
          (f) => f?.totalInsights && f?.totalInsights > 0
        ) ?? []
      }
    />
  );
};

export default InsightListContainer;
