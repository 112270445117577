import { Navigate, Route, Routes } from 'react-router-dom';

import { ApiAccessDocs, ApiAccessKeys } from '@/manager-api-access';
import {
  AuthGuard,
  Loading,
  SignInPage,
  SignOutPage,
  SignUpPage,
} from '@/manager-auth';
import {
  CompanySummary,
  CompanySummaryEdit,
  Dashboard,
  Home,
} from '@/manager-dashboard';
import { InsightDetail, InsightList } from '@/manager-insights';
import { Landing } from '@/manager-landing';
import { Questions } from '@/manager-questions';
import { UserManagement } from '@/manager-user-management';
import { NotFound } from '@/shared-ui';
import { useAuth } from '@workos-inc/authkit-react';

export default function AppRoutes() {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="login" element={<SignInPage />} />
      <Route path="logout" element={<SignOutPage />} />
      <Route path="sign-up" element={<SignUpPage />} />
      <Route path="home">
        <Route index element={<AuthGuard component={Home} />} />
        <Route path="dashboard" element={<AuthGuard component={Dashboard} />} />
        <Route path="company-summary">
          <Route index element={<AuthGuard component={CompanySummary} />} />
          <Route
            path="edit"
            element={<AuthGuard component={CompanySummaryEdit} />}
          />
        </Route>
      </Route>
      <Route path="questions" element={<AuthGuard component={Questions} />} />
      <Route path="insights">
        <Route index element={<AuthGuard component={InsightList} />} />
        <Route path=":id" element={<AuthGuard component={InsightDetail} />} />
      </Route>
      <Route
        path="user-management"
        element={<AuthGuard component={UserManagement} />}
      />
      <Route path="api-access">
        <Route index element={<Navigate to="keys" />} />
        <Route path="keys" element={<AuthGuard component={ApiAccessKeys} />} />
        <Route path="docs" element={<AuthGuard component={ApiAccessDocs} />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
