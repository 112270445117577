import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';

import { StateMessage } from '@/shared-ui';

export const DashboardEmptyState = () => {
  return (
    <div className="flex min-h-[80vh] min-w-full items-center">
      <StateMessage
        heading="No insights found"
        icon={faMagnifyingGlass}
        body="It looks like you haven’t received any Insights yet. Your Insights will appear here once people reply your pulse"
      />
    </div>
  );
};

export default DashboardEmptyState;
