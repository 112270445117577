import {
  faArrowRightFromBracket,
  faCreditCard,
  faKey,
  faUserGear,
} from '@fortawesome/pro-light-svg-icons';
import { Content, Portal, Root, Trigger } from '@radix-ui/react-dropdown-menu';
import { useAuth } from '@workos-inc/authkit-react';

import { BillingPlanCode } from '@/manager-graphql';
import { useUpgrade, useUpgradeBilling } from '@/manager-upgrade';
import { useNavigate } from 'react-router-dom';
import SidebarFooterMenuButtonItem from './manager-sidebar.footer-menu-button-item';
import SidebarFooterMenuItem from './manager-sidebar.footer-menu-item';
import { useSidebar, useSidebarData } from './manager-sidebar.hooks';

export default function SidebarFooterMenu() {
  const { isExpanded } = useSidebar();
  const { hasValidOrg } = useSidebarData();
  const { handleToggleUpgrade, planDetails } = useUpgrade();
  const navigate = useNavigate();

  const { user } = useAuth();
  const { handleCustomerPortalSession } = useUpgradeBilling();

  const handleApiAccess = () => {
    if (planDetails?.planCode === BillingPlanCode.Free) {
      handleToggleUpgrade();
      return;
    } else {
      navigate('/api-access');
    }
  };

  const name: string =
    user?.firstName || user?.lastName
      ? `${user?.firstName} ${user.lastName}`.trim()
      : user?.email || '';

  return (
    <div className="mx-4 border-t border-zinc-800 pb-6 pt-2">
      <Root>
        <Trigger
          aria-label="Settings"
          data-expanded={isExpanded}
          className="group relative w-full rounded-full text-left last:flex focus:outline-none data-[expanded=true]:rounded-md"
        >
          {user?.profilePictureUrl ? (
            <img
              className="h-10 w-10 rounded-full"
              src={user.profilePictureUrl}
              alt="Avatar"
            />
          ) : (
            <div className="font-display flex h-10 w-10 items-center justify-center rounded-full bg-rose-100 text-xl uppercase text-red-700">
              {name
                .split(' ')
                .map((n) => n.charAt(0))
                .join('')}
            </div>
          )}

          {isExpanded ? (
            <div className="ml-2 w-40 overflow-hidden">
              <p className="truncate text-sm font-semibold leading-6 tracking-tight text-white">
                {name}
              </p>
              <p className="truncate text-xs font-normal leading-tight tracking-tight text-neutral-400">
                {user?.email}
              </p>
            </div>
          ) : (
            <div className="invisible absolute left-full ml-6 -translate-x-3 whitespace-nowrap rounded-md bg-zinc-100 px-2 py-1 text-sm text-zinc-800 opacity-20 transition-all group-hover:visible group-hover:translate-x-0 group-hover:opacity-100">
              {name}
            </div>
          )}
        </Trigger>

        <Portal>
          <Content
            side="right"
            className="data-[side=right]:animate-slide-left-and-fade inline-flex min-w-60 flex-col items-center justify-start gap-1 rounded-xl bg-white p-1 shadow will-change-[opacity,transform]"
            sideOffset={32}
          >
            {hasValidOrg ? (
              <>
                <SidebarFooterMenuItem
                  icon={faUserGear}
                  text="User Management"
                  to="/user-management"
                />
                <SidebarFooterMenuButtonItem
                  icon={faKey}
                  text="API Access"
                  onClick={handleApiAccess}
                />
                {planDetails &&
                  planDetails.planCode !== BillingPlanCode.Free && (
                    <SidebarFooterMenuButtonItem
                      icon={faCreditCard}
                      text="Plans & Billing"
                      onClick={handleCustomerPortalSession}
                    />
                  )}
              </>
            ) : null}
            <SidebarFooterMenuItem
              icon={faArrowRightFromBracket}
              text="Log out"
              to="/logout"
            />
          </Content>
        </Portal>
      </Root>
    </div>
  );
}
