import { Rating } from '@/manager-graphql';
import {
  faArrowUpFromSquare,
  faChevronLeft,
  faPlus,
  faRotateRight,
  faShieldCheck,
  faSquarePollVertical,
  faTableColumns,
} from '@fortawesome/pro-light-svg-icons';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QuestionsSurveyRatingsPreview } from './questions-survey-ratings-preview';

interface QuestionsSurveyDesktopPreviewProps {
  question: string;
  ratings: Rating[];
}

export const QuestionsSurveyDesktopPreview = ({
  question,
  ratings,
}: QuestionsSurveyDesktopPreviewProps) => {
  return (
    <div className="m-6 text-xs">
      <div className="shadow-base m-auto w-full rounded-md border bg-white ">
        <div className="flex h-9 items-center space-x-1 bg-gray-200 pl-2 pr-2">
          <div className="flex items-center space-x-1 bg-gray-200 pl-1 pr-1">
            <span className="h-2 w-2 rounded-xl bg-red-400" />
            <span className="h-2 w-2 rounded-xl bg-yellow-400" />
            <span className="h-2 w-2 rounded-xl bg-green-300" />
            <span className="w-2" />
            <FontAwesomeIcon icon={faSquarePollVertical} fontSize={8} />
            <span className="w-4" />
            <FontAwesomeIcon icon={faChevronLeft} fontSize={8} />
          </div>
          <div className="flex items-center space-x-1 bg-gray-200 pl-4 pr-4">
            <FontAwesomeIcon icon={faShieldCheck} fontSize={8} />
            <span className="w-1" />
            <div className="flex items-center gap-2 rounded-xl bg-white p-1 pl-2 pr-2">
              <FontAwesomeIcon icon={faLock} fontSize={8} />
              <span className="max-w-44 overflow-hidden truncate text-xs text-zinc-400">
                {window.origin}/surveys
              </span>
              <FontAwesomeIcon icon={faRotateRight} fontSize={8} />
            </div>
          </div>
          <div className="flex items-center space-x-1 bg-gray-200">
            <FontAwesomeIcon icon={faArrowUpFromSquare} fontSize={8} />
            <span className="w-1" />
            <FontAwesomeIcon icon={faPlus} fontSize={8} />
            <span className="w-1" />
            <FontAwesomeIcon icon={faTableColumns} fontSize={8} />
          </div>
        </div>
        <div className="m-20 flex bg-transparent ">
          <QuestionsSurveyRatingsPreview
            question={question}
            ratings={ratings}
          />
        </div>
      </div>
    </div>
  );
};
